import React, { useRef } from 'react';
import { useEffect } from "react";
import styles from './BackSideOfTheBoard.module.css';
import { useNavigate } from 'react-router-dom';
import interact from 'interactjs';
import 'bootstrap/dist/css/bootstrap.css';
import '../AdminDashBoard/AdminDashBoard.css'
import { useScreenshot } from "use-screenshot-hook";
import { Helmet } from "react-helmet";
import GetData from "../../BackSideBoard.json"
import { Link } from 'react-router-dom'
import { v4 as uuid } from 'uuid';
import { GoogleLogout } from 'react-google-login';
import Learnup from '../../assets/images/v2_7.png';
import '../../contexts/position-style.css'

const clientId = '478729096121-acrvqi9nptdhg2j8jsbnnoomjjnr5g7q.apps.googleusercontent.com'
const createFileName = (extension = "", ...names) => {
    if (!extension) {
      return "";
    }
    // const current = new Date();
    // const date = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()} ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
    // console.log(date);
    return `${names.join("")}.${extension}`;
  };
  
let clickchar = (id) => {
    console.log(id);
    // let urld=id.replace("_drop","");
    const parse_array = id.split('_');
    let urld = parse_array[0];
    let path_char = parse_array[1];
    //console.log('path_char', path_char);
    if(urld=="con")
    urld=urld+"-";
    if(urld=="true")
    urld="ture";
    console.log('url', urld);
    if(urld=="le")
    urld="tle";
    console.log('url', urld);

    console.log('path_char', path_char);
    let audiofileurl = process.env.PUBLIC_URL + '/audio/backofboard/' + path_char + '/' + urld + '.mp3';
    let payeaudio = new Audio(audiofileurl);
    payeaudio.play();
}
// interact('.drag-drop')
// .on('hold', function (event) {
//     const { currentTarget, interaction } = event;
//     let element = currentTarget;
//     element = currentTarget.cloneNode(true);
//     let id = currentTarget.id;
//     const parse_array = id.split('_');
//     let path_char = parse_array[1];
//     element.id=element.id+'_'+uuid();
//       // Add absolute positioning so that cloned object lives right on top of the original object
//       element.style.position = "absolute"; 
//       // element.style.position = "relative";
//       element.clientX = event.clientX+30;
//       element.clientY = event.clientY-30;
//       element.style.left = event.clientX+25+'px'; //(20+event.clientX+10);
//       element.style.top = event.clientY-25+'px';
//     var container;
//     switch (path_char) {
//         case 'prefixes':
//             container = document.getElementById("div_prefixess");
//             break;
//         case 'endings':
//             container = document.getElementById("div_endings");
//             break;
//         case 'roots':
//             container = document.getElementById("div_rootss");
//             break;
//     }
//     // const container = document.getElementById("_consonants");
//     // container && container.appendChild(element);
//     console.log(container)
//     if (container) {
//       container && container.appendChild(element)
//     }
//   });
// function resetdiv() {
//     console.log("Reset");
//     // var myDiv = document.getElementById("outer-dropzone");
//     //   myDiv.innerHTML = "";
//     var element = document.querySelectorAll('.drag-drop');
//     for (let i = 0; i < element.length; i++) {
//         console.log(element[i]);
//         localStorage.removeItem(element[i].id);
//         let p1 = element[i].removeAttribute("style");
//         let p2 = element[i].removeAttribute("data-x");
//         let p3 = element[i].removeAttribute("data-y");
//         let p4 = element[i].removeAttribute("left");
//         let p5 = element[i].removeAttribute("right");
//         let p6 = element[i].removeAttribute("top");
//     }
// }
function resetdiv()
{
  console.log("Reset");
  // var myDiv = document.getElementById("outer-dropzone");
  //   myDiv.innerHTML = "";
  var element = document.querySelectorAll('.drag-drop');
    for(let i=0;i<element.length;i++){
      // console.log(element[i]);
      localStorage.removeItem(element[i].id);
      let p1=element[i].removeAttribute("style");
      let p2=element[i].removeAttribute("data-x");
      let p3=element[i].removeAttribute("data-y");
      let p4=element[i].removeAttribute("left");
      let p5=element[i].removeAttribute("right");
      let p6=element[i].removeAttribute("top");
      const parse_array = element[i].id.split('_');
      let urld=parse_array[0];
      if(parse_array[2]){
        // console.log( element[i].id);
        var t=document.getElementById(element[i].id);
        t.remove();
      }
      else{
        document.getElementById(element[i].id).style.display='inline-block';
      }
      // $('#wPaint-img').clear()
      //.wPaint('clear');
    }
    var values = [],LocalStorageitemsKeys1 = Object.keys(localStorage),
  j = LocalStorageitemsKeys1.length;
  while (j--) {
    for (let g = 0; g < element.length; g++) {
      //console.log(element[g]);
    const parse_array1 = LocalStorageitemsKeys1[j].split('_');
    if(parse_array1[0]=="hide" && element[g].id==(parse_array1[1]+"_"+parse_array1[2]))
    {
      localStorage.removeItem(LocalStorageitemsKeys1[j]);
    }
  }
  // console.log($('#wPaint').wPaint('clear'));
  }
    // console.log($('#wPaint').wPaint('clear'));
}
function diffrence(a, b) {
    return a > b ? a - b : b - a
}
// function clonediv(event) {
//     console.log(event.currentTarget.id)
//     let pppp = event.currentTarget.id;
//     var element = document.querySelectorAll('.drag-drop');
//     let j = 0;
//     for (let i = 0; i < element.length; i++) {
//         //console.log(element[i].id);
//         var t = [];
//         if (element[i].id != event.currentTarget.id) {
//             let left = element[i].getAttributeNode("left");
//             let right = element[i].getAttributeNode("right");

//             if (left && right) {
//                 if (diffrence((element[i].getAttributeNode("top").value), event.rect.top) <= 30) {
//                     if (diffrence(event.rect.left, right.value) < 30) {
//                         //console.log("left padding");
//                         event.target.style.marginLeft = '0px';
//                         j = j + 1;
//                         let id = pppp;
//                         let top = element[i].getAttributeNode("top");
//                         let bottom = element[i].getAttributeNode("bottom");
//                         // event.rect["top"]=top.value;
//                         // event.rect["bottom"]=bottom.value;
//                         event.rect["data_x"] = event.target.dataset.x;
//                         event.rect["data_y"] = event.target.dataset.y;
//                         localStorage.setItem(id, JSON.stringify(event.rect));
//                     }
//                     else if (diffrence(event.rect.right, left.value) < 30) {
//                         //console.log("right padding");
//                         event.target.style.marginRight = '-10px';
//                         j = j + 1;
//                         let id = pppp;
//                         let top = element[i].getAttributeNode("top");
//                         let bottom = element[i].getAttributeNode("bottom");
//                         // event.rect["top"]=top.value;
//                         // event.rect["bottom"]=bottom.value;
//                         event.rect["data_x"] = event.target.dataset.x;
//                         event.rect["data_y"] = event.target.dataset.y;
//                         localStorage.setItem(id, JSON.stringify(event.rect));
//                     }
//                 }

//             }

//         }
//     }
//     if (j == 0) {
//         event.target.style.marginLeft = '8px';
//         event.target.style.marginRight = '8px';
//         let id = pppp;
//         event.rect["data_x"] = event.target.dataset.x;
//         event.rect["data_y"] = event.target.dataset.y;
//         localStorage.setItem(id, JSON.stringify(event.rect));
//     }
// }

//     // Start the drag event
//     interaction.start({ name: "drag" }, event.interactable, element);
const draggableOptions = {
    onmove: event => {
        const target = event.target;
        const dx = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
        const dy = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
        target.style.webkitTransform = target.style.transform =
            "translate(" + dx + "px, " + dy + "px)";

        // update the posiion attributes
        target.setAttribute("data-x", dx);
        target.setAttribute("data-y", dy);
    },
    onend: event => {
        event.target.setAttribute("left", event.rect.left);
        event.target.setAttribute("right", event.rect.right);
        event.target.setAttribute("top", event.rect.top);
        event.target.setAttribute("bottom", event.rect.bottom);
        console.log(event.currentTarget.id);
        console.log(event.rect);
        console.log(event.target.dataset.x);
        console.log(event.target.dataset.y);
    }

};
function showMenu() {
    document.getElementById('menu').style.width = "300px";
}

function hideMenu() {
    document.getElementById('menu').style.width = "0px";
}


    export default function BackSideOfTheBoard() {

        const ref = useRef(null);
        const { image, takeScreenshot, isLoading, clear } = useScreenshot();
        const download = (image, { name = "LearnUp", extension = "jpg" } = {}) => {
            const a = document.createElement("a");
            a.href = image;
            const current = new Date();
            const date = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()} ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
            name = name + date.toString();
            a.download = createFileName(extension, name);

            a.click();
        };
        // const myStyle = {
        //     height: '60%',
        //     width: '90%',
        //     margin: 'auto'
        // };
        function showMenu(){

            document.getElementById('menu').style.display="block";
          }
          
          function hideMenu(){
            document.getElementById('menu').style.display="none";
          }
        const downloadScreenshot = () => takeScreenshot(ref.current).then(download);

        const navigate = useNavigate();

        function LogOut() {

            document.getElementById('pop-msg').style.display = 'block'
        }
        function hide() {
            document.getElementById('pop-msg').style.display = 'none'
        }
        const onSuccess = () => {
            console.log('Logout Made Successfully');
            // alert('Logout Made Successfully ✌');
            resetdiv();
            localStorage.clear();
            navigate("/thankyou")
          };
        useEffect(() => {
            
    const renderGoogleButton = localStorage.getItem('GLogin')
console.log("GoogleLOginButton",renderGoogleButton);
const renderNormalLogoutButton = localStorage.getItem('NLogin')
console.log("Normal login button",renderNormalLogoutButton);

if(renderGoogleButton=='true'){
  document.getElementById('GoogleLogout').style.display='block'
    
  
  }
  
  if(renderNormalLogoutButton =='true'){
    document.getElementById('NormalLogout').style.display='block'
  }
            var getAllTiles = document.querySelectorAll('.drag-drop');
            var values = [],
                LocalStorageitemsKeys = Object.keys(localStorage),
                i = LocalStorageitemsKeys.length;
            while (i--) {
                let divdata = JSON.parse(localStorage.getItem(LocalStorageitemsKeys[i]));
                console.log(typeof divdata);
                console.log(divdata["left"]);
                console.log(divdata["right"]);
                console.log(divdata["top"]);
                console.log(divdata);


                for (var g = 0; g < getAllTiles.length; g++) {
                    console.log(getAllTiles[g]);
                    const parse_array = LocalStorageitemsKeys[i].split('_');
                    if(parse_array[0]=="hide" && getAllTiles[g].id==(parse_array[1]+"_"+parse_array[2]))
                    {
                        document.getElementById(getAllTiles[g].id).style.visibility = 'hidden';
                    }
                    if (LocalStorageitemsKeys[i] == getAllTiles[g].id) {
                        var elem = document.getElementById(getAllTiles[g].id);
                        elem.style.transform = "translate(" + divdata["data_x"] + "px, " + divdata["data_y"] + "px)";
                        getAllTiles[g].setAttribute("left", divdata["left"]);
                        getAllTiles[g].setAttribute("right", divdata["right"]);
                        getAllTiles[g].setAttribute("top", divdata["top"]);
                        getAllTiles[g].setAttribute("data-x", divdata["data_x"]);
                        getAllTiles[g].setAttribute("data-y", divdata["data_y"]);
                    }
                    else if((parse_array[0]+'_'+parse_array[1])==getAllTiles[g].id && parse_array[2])
                    {
          
                        var newDiv = document.createElement('div');
                        newDiv.setAttribute("id", LocalStorageitemsKeys[i]);
                        //newDiv.style.transform = "translate(" + divdata["data_x"] + "px, " + divdata["data_y"] + "px)";
                        newDiv.setAttribute("left", divdata["left"]);
                        newDiv.setAttribute("right", divdata["right"]);
                        newDiv.setAttribute("top", divdata["top"]);
                        // newDiv.setAttribute("data-x", divdata["data_x"]);
                        // newDiv.setAttribute("data-y", divdata["data_y"]);
                        document.getElementById('whiteboard').appendChild(newDiv);
                        document.getElementById(LocalStorageitemsKeys[i]).innerHTML=divdata["eventName"];
                        document.getElementById(LocalStorageitemsKeys[i]).className =divdata["eventClass"];
                        var elem = document.getElementById(LocalStorageitemsKeys[i]);
                        elem.style.position="absolute";
                        elem.style.left=divdata["left"]-parseFloat('50')+'px';
                        elem.style.top=divdata["top"]-parseFloat('50')+'px';
            // elem.style.transform="";
            //elem.style.transform = "translate(" + divdata["data_x"] + "px, " + divdata["data_y"] + "px)";
                    }
                }
                console.log('Component mounted');
            }
            return () => {
                console.log('Component will be unmount');
            }
        }, []);
        return (
            <>
                <Helmet>
                    <link rel="Stylesheet" type="text/css" href="/demo/demo.css" />
                    <link rel="Stylesheet" type="text/css" href="/lib/wColorPicker.min.css" />
                    <link rel="Stylesheet" type="text/css" href="/wPaint.min.css" />
                    <script async type="text/javascript" src="/lib/index.js"></script>
                </Helmet>
                <div className={styles.Whiteboard} style={{ height: 80 }}>
                <div className="Maindiv">
       <div><span id='mainbox' onClick={showMenu}>&#9776;</span></div> 
      
        <div className="sidemenu" id='menu'>
          
          <div>
            <img src={Learnup} alt="Learnup Logo" className="sidemenu-logo" />
            <span>
            <a className='close-btn' onClick={hideMenu} >&times;</a>
            </span>
          </div>
       <div className="sidemenu-list">
          <ul style={{padding:"0px",marginTop: "5px"}}>
            <li><a href="#">Assessment</a></li>
            <li><a href="#">Reading Lessons</a></li>
            <li><a href="#">Spelling Sounds</a></li>
            <li><a href="#">Reading Sounds</a></li>
            <li><a href="#">Reading Syllables</a></li>
            <li><a href="#">Spelling Syllables</a></li>
            <li><a href="#">Reading Words</a></li>
            <li><a href="#">Spelling Words</a></li>
            <li><a href="#">Written Response</a></li>
            <li> <a id='NormalLogout' onClick={LogOut}>Logout</a>
               <div  id="GoogleLogout">
    
          
          <GoogleLogout
                    clientId={clientId}
                    buttonText="Logout"
                    onLogoutSuccess={onSuccess}
                  
          ></GoogleLogout>
         </div></li>
          </ul>
          

           
           </div>
        </div>
         
         <div className="navbar row">
             <div className="col-2"></div>
           <div className='col-5'>
           {/* <i className="bi bi-bootstrap-reboot"></i> */}
           <Link to="/home">
                <button className='button btn btn-light rounded mx-2'>
                                {"Front Board"}
                </button>
            </Link>
           <button className="button btn btn-light rounded mx-2" onClick={resetdiv}>Reset</button>
           </div>
            <div className="icon-class-navbar col-2">
            {/* <button>Anotation</button> */}
            <i className="bi bi-camera-fill  icon-camera" onClick={() => downloadScreenshot()}></i>
            <i className="bi bi-person icon-user"></i>
           </div>
         </div>
         <div id="pop-msg" className="modal">
            <h6 className='mb-4'>Are you sure you want to Logout?</h6>

            <button type="button" onClick={hide} className="cancelbtn btn btn-danger ">Cancel</button>
            <button type="button" onClick={() => {
              localStorage.clear();
              resetdiv();
              navigate("/thankyou")

            }} className="cancelbtn btn btn-primarys px-4 mx-4">ok
            </button>
          </div>
                    {/* <div className='grid-container1 mt-2'>
                        <div id='mainbox' onClick={showMenu}>&#9776;</div>
                        <div className='sidemenu' id='menu'>
                            <div className="sidemenu-List">
                                <ul style={{ padding: "0px" }}>
                                    <li>
                                        <a href="#">Assessment</a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a href="#"> Reading Lessons</a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a href="#"> Spelling Sounds</a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a href="#"> Reading Sounds</a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a href="#">  Spelling Syllables</a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a href="#"> Reading Syllables</a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a href="#"> Spelling Words</a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a href="#"> Reading Words</a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a href="#">Written Response   </a>
                                        <hr />
                                    </li>
                                    <li>
                                        <a onClick={LogOut}>Log out</a>
                                    </li>
                                    <li>
                                        <a className='closebtn' onClick={hideMenu} >&times;</a>
                                    </li>
                                </ul>
                            </div>


                        </div>
                        <div id="pop-msg" className="modal">
                            <h6 className='mb-4'>Are you sure you want to log out?</h6>

                            <button type="button" onClick={hide} className="cancelbtn btn btn-danger ">Cancel</button>
                            <button type="button" onClick={() => {
                                localStorage.clear();
                                navigate("/thankyou")

                            }} className="cancelbtn btn btn-primary px-4 mx-4">ok
                            </button>
                        </div>
                            <button className='button btn btn-light' onClick={resetdiv}>Reset</button>
                        <button className='button btn btn-light' onClick={() => downloadScreenshot()}>
                            {"Screenshot"}
                        </button>
                        <Link to="/home">
                            <button className='button btn btn-light'>
                                {"Front Board"}
                            </button>
                        </Link>

                    </div> */}
                    <div id="whiteboard" className="backwhiteboard">
                        <div className='main-container'>
                            {/* <div className="row pt-3" > */}
                            <div className="row">
                                <div className="col-2 col-lg-2 col-md-2 col-sm-2" id="rfamily">
                                    <div className='_Prefixes'>
                                    {GetData[0].Prefixes.data.map(items => {
                                        // console.log(items.length )
                                        switch (items.length) {
                                            case 1:
                                                return (
                                                    <div id={items + '_Prefixe'} className="drag-drop ddrag-drop_Vowels drag-drop_prifixes-one noselect" onDoubleClick={() => { clickchar(items + '_Prefixe') }}> {items} </div>
                                                )
                                            case 2:
                                                return (
                                                    <div id={items + '_Prefixe'} className="drag-drop drag-drop_Vowels drag-drop_prifixes-two noselect" onDoubleClick={() => { clickchar(items + '_Prefixe')  }}> {items} </div>
                                                )
                                            case 3:
                                                return (
                                                    <div id={items + '_Prefixe'} className="drag-drop drag-drop_Vowels drag-drop_prifixes-three noselect" onDoubleClick={() => { clickchar(items + '_Prefixe') }}> {items} </div>
                                                )
                                            case 4:
                                                return (
                                                    <div id={items + '_Prefixe'} className="drag-drop drag-drop_Vowels drag-drop_prifixes-four noselect" onDoubleClick={() => { clickchar(items + '_Prefixe') }}> {items} </div>
                                                )
                                            case 5:
                                                return (
                                                    <div id={items + '_Prefixe'} className="drag-drop drag-drop_Vowels drag-drop_prifixes-five noselect" onDoubleClick={() => { clickchar(items + '_Prefixe') }}> {items} </div>
                                                )
                                            default:
                                                return (
                                                    <div id={items + '_Prefixe'} className="drag-drop drag-drop_Vowels drag-drop_prifixes-default noselect" onDoubleClick={() => { clickchar(items + '_Prefixe') }}> {items} </div>
                                                )
                                        }
                                    })}
                                    </div>
                                    <div id="div_prefixess"></div>
                                </div>
                                <div className="col-8 col-lg-8 col-md-8 col-sm-8" id="consonants">
                                    <div className='_Roots'>
                                    {GetData[0].Roots.data.map(items => {
                                        switch (items.length) {
                                            case 1:
                                                return (
                                                    <div id={items + '_roots'} className="drag-drop  drag-drop_roots-one noselect" onDoubleClick={() => { clickchar(items + '_roots') }} > {items} </div>
                                                )
                                            case 2:
                                                return (
                                                    <div id={items + '_roots'} className="drag-drop drag-drop_roots-two noselect" onDoubleClick={() => { clickchar(items + '_roots') }} > {items} </div>
                                                )
                                            case 3:
                                                return (
                                                    <div id={items + '_roots'} className="drag-drop drag-drop_roots-three noselect" onDoubleClick={() => { clickchar(items + '_roots') }} > {items} </div>
                                                )
                                            case 4:
                                                return (
                                                    <div id={items + '_roots'} className="drag-drop drag-drop_roots-four noselect" onDoubleClick={() => { clickchar(items + '_roots') }} > {items} </div>
                                                )
                                            case 5:
                                                return (
                                                    <div id={items + '_roots'} className="drag-drop drag-drop_roots-five noselect" onDoubleClick={() => { clickchar(items + '_roots') }} > {items} </div>
                                                )
                                            default:
                                                return (
                                                    <div id={items + '_roots'} className="drag-drop drag-drop_roots-default noselect" onDoubleClick={() => { clickchar(items + '_roots') }} > {items} </div>
                                                )
                                        }
                                    })}
                                    </div>
                                    <div id="div_rootss"></div>
                                </div>
                                <div className="col-2 col-lg-2 col-md-2 col-sm-2" id='endings'>
                                   <div className='_Endings'>
                                   {GetData[0].Suffixes.data.map(items => {
                                        switch (items.length) {
                                            case 1:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-one noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            case 2:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-two noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            case 3:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-three noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            case 4:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-four noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            case 5:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-five noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            default:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-default noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>

                                                )

                                        }

                                    })}
                                   </div>
                                    <div id='position-adj'>
                                    {GetData[0].Suffixes.new_data.map(items => {
                                        switch (items.length) {
                                            case 1:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-one noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            case 2:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-two noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            case 3:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-three noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            case 4:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-four noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            case 5:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-five noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>
                                                )
                                            default:
                                                return (
                                                    <div id={items + '_endings'} className="drag-drop drag-drop_Reds drag-drop_suffixes-default noselect" onDoubleClick={() => { clickchar(items + '_endings') }}> {items} </div>

                                                )

                                        }

                                    })}
                                    </div>
                                    
                                    <div id="div_endings"></div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                {/* <div id="wPaint"></div>
            <div id="wPaint-img"></div> */}
            </div>
            </>
        )
    }
