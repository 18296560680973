import React, {useEffect} from 'react'
import { useState } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { authentication } from '../../contexts/firebaseconfig';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import logo from '../../../src/assets/images/v2_7.png'
import '../../contexts/style.css'
import { FaUserAlt, FaUnlockAlt } from "react-icons/fa";


export default function SignInPage() {
  //const clientId = '478729096121-acrvqi9nptdhg2j8jsbnnoomjjnr5g7q.apps.googleusercontent.com'
   const clientId = '362324604519-3grs2vpsetl9t3reieovlkgh93404oa2.apps.googleusercontent.com'
  const API_Key = 'AIzaSyCeMVQIgdS6PqmmW8sKwK1IWBzOmmbUVOc';
  const Client_secret = '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';
  // '707788443358-u05p46nssla3l8tmn58tpo9r5sommgks.apps.googleusercontent.com';
  //facebook login start
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState('');


  

  const signInwithGoogle = (e) => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((result) => {

        console.log(result);
        // navigate("/")
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // const responseFacebook = (response) => {
  //     console.log(response);
  //     setData(response);
  //     setPicture(response.picture.data.url);
  //     if (response.accessToken) {
  //       setLogin(true);
  //     } else {
  //       setLogin(false);
  //     }
  //   }
  //end

  const [GLogin,setGLogin] = useState('0');
  const [NLogin,setNLogin] = useState('0');


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setSubmitted(false);
  };

  useEffect(() => { 
    const accesssToken = localStorage.getItem('TockenID')
    console.log('token', accesssToken);
       if(accesssToken){
         localStorage.setItem('NLogin', '1');
        setNLogin('1')
        navigate("/home")
       }else{
        navigate("/login")
       }
  },[])

  // Handling the password change
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setSubmitted(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (email === '' || password === '') {
    // setError(true);
    // } else {
    console.log(email)
    console.log(password)
    //https://learnupdevwebapi.azurewebsites.net
    //https://learnupuatwebapi.azurewebsites.net
    // fetch('https://localhost:44359/api/Subscriber/LoginUser?emailid='+email+'&password='+password, {
    fetch('https://learnupcenterapi.azurewebsites.net/api/Subscriber/LoginUser?emailid=' + email + '&password=' + password, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((Response) => Response.json())
      .then((Result) => {
        console.log("in result")
        if (Result) {
          if (Result["Role"] == "Tutor" || Result["Role"] == "Student") {
            console.log("in resul 111t", Result)

            localStorage.setItem('Name', JSON.stringify(Result["FullName"]));
            localStorage.setItem('UserId', JSON.stringify(Result["Id"]));
            localStorage.setItem('Role', JSON.stringify(Result["Role"]));
            localStorage.setItem('RoleId', JSON.stringify(Result["RoleId"]));
            localStorage.setItem('TockenID', JSON.stringify(Result["Id"]));
            localStorage.setItem('soundWhooshWB', JSON.stringify(true));
            localStorage.setItem('soundTilesWB', JSON.stringify(true));
            localStorage.setItem('soundWhooshGB', JSON.stringify(true));
            localStorage.setItem('soundTilesGB', JSON.stringify(true)); 
            localStorage.setItem('soundWhooshSB', JSON.stringify(true));
            localStorage.setItem('soundTilesSB', JSON.stringify(true));
            setNLogin('1')
            // console.log("hello")

            setTimeout(() => {
              setNLogin('1')
              navigate("/home")
            }, 2000)

            setSubmitted(true);
            setError(false);
          }

          else if (Result["Role"] == "Admin") {
            localStorage.setItem('Name', JSON.stringify(Result["FullName"]));
            localStorage.setItem('UserId', JSON.stringify(Result["Id"]));
            localStorage.setItem('Role', JSON.stringify(Result["Role"]));
            localStorage.setItem('RoleId', JSON.stringify(Result["RoleId"]));
            setTimeout(() => {
              setNLogin('1')
              navigate("/AdminDashboard")
            }, 2000)

            setSubmitted(true);
            setError(false);
          }
        }
        else {
          console.log("user details not found");

          setError(true);

          setTimeout(() => {
            setError(false);
          }, 2000);
        }
      })
  }

  const onSuccess = (res) => {
    console.log('Login Success: currentUser:', res.profileObj);
    localStorage.setItem('GoogleDetails', JSON.stringify(res.profileObj));
    localStorage.setItem('TockenID', JSON.stringify(res.tokenObj.access_token));
    localStorage.setItem('soundWhooshWB', JSON.stringify(true));
    localStorage.setItem('soundTilesWB', JSON.stringify(true));
    localStorage.setItem('soundWhooshGB', JSON.stringify(true));
    localStorage.setItem('soundTilesGB', JSON.stringify(true)); 
    localStorage.setItem('soundWhooshSB', JSON.stringify(true));
    localStorage.setItem('soundTilesSB', JSON.stringify(true));
    // alert(
    //   `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
    // );
    setGLogin('1')
    navigate("/home")
    // refreshTokenSetup(res);
  };

  localStorage.setItem('GLogin', GLogin);
  localStorage.setItem('NLogin', NLogin);

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
    // alert(
    //   `Failed to login. 😢`
    // );
  };



  const successMessage = (successTitle) => {

    return (
      <div
        className="success"
        style={{
          display: submitted ? '' : 'none',
        }}>
        {/* <h1 >User {email} successfully registered!!</h1> */}
        <h6 className="text-success   bg-light p-1 border border-success border border-3 rounded-pill  mt-1" id="LoginSuccessMsg">{successTitle}</h6>
      </div>
    );
  };


  // Showing error message if error is true
  const errorMessage = (errorTitle) => {
    console.log("eroemsx", error)
    return (
      <div
        className="error"
        style={{
          display: error ? '' : 'none',
        }}>

        <h6 className="text-danger   bg-light p-1 border border-danger border border-3 rounded-pill  mt-1" id="LoginSuccessMsg">{errorTitle}</h6>
      </div>
    );
  };

  // function Login() {
  //     console.log("hello")


  //       }

  function showLoginForm() {
    document.getElementById("hideLoginForm").style.display = "block";
    document.getElementById("hideGoogleForm").style.display = "none"
  }
  function hideLoginForm() {
    document.getElementById("hideLoginForm").style.display = "none";
    document.getElementById("hideGoogleForm").style.display = "block"
  }

  function showpassword() {
    document.getElementById("showpassword").style.display = "block"
    document.getElementById("hidepassword").style.display = "none"
    document.getElementById("password-type").type = "password";
  }
  function hidepassword() {
    document.getElementById("showpassword").style.display = "none"
    document.getElementById("hidepassword").style.display = "block"
    document.getElementById("password-type").type = "text";

  }
  return (
    <>
      <div className="text-center head">
        <div className='login-content' >
          <img src={logo} className="logo-img" />
          <div className="messages">
            {errorMessage("Please Enter Correct Details")}
            {successMessage("User Successfully Login")}
          </div>
          <div className='usr-txt'>User Login</div>
          {/* <div id="hideGoogleForm">
          <div className="google-login">
              <GoogleLogin
                clientId={clientId}
                buttonText="Login with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
              />
            </div>
            <footer>
            <button  className='login-btn' onClick={showLoginForm}>ALTERNATE LOGIN</button>
            </footer>
            </div> */}
          <form >
            {/* <div className='usr-txt'>User Login</div> */}
            {/* <div className="google-login">
              <GoogleLogin
                clientId={clientId}
                buttonText="Login with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
              />
            </div> */}
            {/* <div id='hideLoginForm' style={{display:'none'}}> */}
            <div id='hideLoginForm'>
          
            
            <div className='passfield'>
              <span className='icon-adj mr-3'><FaUserAlt /></span>
              <input className='v20_10' type="text" autocomplete="off" onChange={handleEmail} value={email} name="name" placeholder='Username' required />
            </div>
            <div className="passfield">
              <span className='icon-adj'> <FaUnlockAlt /></span>
           
              <input className='v20_10 mt-4' placeholder='Password' id="password-type"
                type="password"
                autocomplete="off"
                name="password"
                value={password}
                onChange={handlePassword}
                required />
            
            <i className="bi bi-eye-fill show-hide-password" id="hidepassword" onClick={showpassword}></i>

<i className="bi bi-eye-slash-fill show-hide-password" id="showpassword"
  onClick={hidepassword}
></i> 

            </div>
            <div className='mt-4'>
              <button className='login-btn' onClick={handleSubmit} type="submit">Login</button>
            </div>

            {/* <button  className='google-btn' onClick={hideLoginForm}>Google</button> */}

           <div className="footer">
           <p><Link to="/forget-password">Forgot password?</Link></p>
            
            <p>Don't have an account? <Link to="/register">Sign up</Link>.</p>
         
           </div>
          
            </div>
            <div>
            <h3 style={{color:'black'}}>or</h3>
            </div>
            <div className="google-login">
              <GoogleLogin
                clientId={clientId}
                buttonText="Login with Google"
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
              />
            </div>
          </form>
        </div>
        
      </div>
    </>
  )
};
