import React, { useState } from "react";
import { Link, Navigate } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import interact from 'interactjs';
import $ from 'jquery';
import '../AdminDashBoard/AdminDashBoard.css'
export default function AdminDashBoard() {
    const Navigate = useNavigate();
var alphabets={
    "alphabets":[
     {
      "Letter": "b",
      "Name": "Consonants",
      "Color": "White"
     },
     {
      "Letter": "c",
      "Name": "Consonants",
      "Color": "White"
     },
     {
      "Letter": "d",
      "Name": "Consonants",
      "Color": "White"
     },
     {
      "Letter": "f",
      "Name": "Consonants",
      "Color": "White"
     }],
    }
    var LastDraggedX = 0;

    // target elements with the "draggable" class
    interact(".draggable").draggable({
        // enable inertial throwing
        inertia: true,
        // keep the element within the area of it's parent
        modifiers: [
            interact.modifiers.snap({
                targets: [
                    interact.snappers.grid({ x: 30, y: 30 })
                ],
                range: Infinity,
                relativePoints: [{ x: 0, y: 0 }]
            }),
            //interact.modifiers.restrict({
            //    restriction: element.parentNode,
            //    elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            //    endOnly: true
            //}),
            interact.modifiers.restrictRect({
                restriction: "parent",
                endOnly: true,
            }),
        ],
        // enable autoScroll
        autoScroll: true,

        listeners: {
            // call this function on every dragmove event
            move: dragMoveListener,

            // call this function on every dragend event
            end(event) {
                               
                if (LastDraggedX > $('#board').offset().left + $('#board').width()) {
                    event.target.remove();
                    LastDraggedX = 0;
                }
                $('.quickInput').remove();

                var textEl = event.target.querySelector("p");
                textEl && (
                    textEl.textContent =
                        Math.sqrt(
                            (Math.pow(event.pageX - event.x0, 2) +
                                Math.pow(event.pageY - event.y0, 2)) |
                            0
                    ).toFixed(2)
                );
            },
        },
    });

    function dragMoveListener(event) {

        if (event.client.x > LastDraggedX)
        LastDraggedX = event.client.x;

        var target = event.target;
        // keep the dragged position in the data-x/data-y attributes
        var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
        var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

        // translate the element
        target.style.transform = "translate(" + x + "px, " + y + "px)";

        // update the posiion attributes
        target.setAttribute("data-x", x);
        target.setAttribute("data-y", y);
    }
    $('#addTile').on('click', function (e) {
        $("#board").prepend('<div id="drag-' + $('.draggable').length + '" class="draggable"><span> Click Me </span></div>');
    })
    $(function () {
        var isDragging = false;
        $("body").on('mousedown','.draggable', function () {
                $(window).mousemove(function () {
                    isDragging = true;
                    $(window).unbind("mousemove");
                });
        }).on('mouseup','.draggable',function () {
                var wasDragging = isDragging;
                isDragging = false;
            $(window).unbind("mousemove");
            if (!wasDragging && $('.quickInput').length == 0) {
                    $(this).html('<input placeholder="Type Here"  class="quickInput"  style="max-width:70px;" />')

                }
            });
    });


    $('body').on('keypress', '.quickInput', function (e) {

        if (e.keyCode == 13 ) {
            var InputVal = $(this).val();
            let j=0;
            for(let a=0;a<alphabets.alphabets.length;a++)
            {
                console.log(alphabets.alphabets[a]["Letter"])
                if(alphabets.alphabets[a]["Letter"]==InputVal)
                {
                    $(this).id=InputVal+alphabets.alphabets[a]["Name"];
                    $(this).closest('.draggable').html('<span>' + InputVal + '</span>');
                    // $(this).remove();
                    j=j+1;
                }
                
            }
            if(j==0)
            {
            $(this).closest('.draggable').id=InputVal+
            $(this).closest('.draggable').html('<span>' + InputVal + '</span>');
            $(this).remove();
            }
            
            return false; // prevent the button click from happening
        }
    });

    // this function is used later in the resizing and gesture demos
    // window.dragMoveListener = dragMoveListener;
  return (
    <div>
     
    <button id="addTile">Add Tile</button>
    <br />
    <br />
    {/* <div id="board" style="height: 100vh !important; width: 100vw !important"> */}
    <div id="board">
        <div id="drag-0" className="draggable">
            <span> E1 </span>
        </div>
        <div id="drag-1" className="draggable">
            <span> E2 </span>
            </div>
    </div>
    </div>
  );
}
