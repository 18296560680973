import React from "react";
// import logo from './logo.svg';
import "./App.css";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import "./components/Whiteboard/Whiteboard";
import LandingPage from "./components/pages/LandingPage";
import LoginPage from "./components/pages/LoginPage";
import RegisterPage from "./components/pages/RegisterPage";
import ForgetPasswordPage from "./components/pages/ForgetPasswordPage";
import HomePage from "./components/pages/HomePage";
import Thankyou from "./components/pages/Thankyou";
import AdminDashBoard from "./components/AdminDashBoard/AdminDashBoard";
import BackSideOfTheBoard from "./components/BackSideOfTheBoard/BackSideOfTheBoard";
import ResetPassword from "./components/pages/ResetPassword";
import GalaxyBoard from "./components/GalaxyBoard/GalaxyBoard";
import Startboard from "./components/StartBoard/StartBoard";
import GeneralBoard from "./components/GeneralBoard/GeneralBoard";
function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forget-password" element={<ForgetPasswordPage />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/backsideboard" element={<BackSideOfTheBoard />} />
            <Route path="/AdminDashBoard" element={<AdminDashBoard />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/" element={<GeneralBoard />}>
              <Route path="home" element={<HomePage />} />
              <Route path="galaxy-board" element={<GalaxyBoard />} />
              <Route path="start-board" element={<Startboard />} />
            </Route>
          </Routes>
        </div>
      </Router>
      {/* <Whiteboard /> */}
    </div>
  );
}

export default App;
const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
// import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'

// export const App = () => {

//   return (
//     <DndProvider backend={HTML5Backend}>
//       {/* Here, render a component that uses DND inside it */}
//     </DndProvider>
//   )
// }

// export default App
