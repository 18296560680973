import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";

const GeneralBoard = () => {
  return (
    <div className="Maindiv">
      <SideMenu />
      <Outlet />
    </div>
  );
};

export default GeneralBoard;
