import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import Learnup from "../../assets/images/v2_7.png";
import { GoogleLogout } from "react-google-login";
import ReactModal from "react-modal-resizable-draggable";
import WritingExData from "../WritingExData";

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clientId ="478729096121-acrvqi9nptdhg2j8jsbnnoomjjnr5g7q.apps.googleusercontent.com";
  //const clientId ="913025178036-n571tqs5340347lac28s4jtbhqnupdor.apps.googleusercontent.com";
  const [lgShow, setLgShow] = useState(false);
  const [accordion, setAccordion] = useState(false);
  const [value, setValue] = useState(false);

  useEffect(() => {
    const renderGoogleButton = localStorage.getItem("GLogin");
    console.log("GoogleLOginButton", renderGoogleButton);
    const renderNormalLogoutButton = localStorage.getItem("NLogin");
    console.log("Normal login button", renderNormalLogoutButton);

    if (renderGoogleButton == "1") {
      document.getElementById("GoogleLogout").style.display = "block";
    }

    if (renderNormalLogoutButton == "1") {
      document.getElementById("NormalLogout").style.display = "block";
    }
  }, []);
  function SetDefaultPositions(){
    setLgShow(false);
    //reload();
    window.location.reload();
  }
  function showMenu() {
    document.getElementById("menu").style.display = "block";
  }

  function hideMenu() {
    document.getElementById("menu").style.display = "none";
  }
  
  const _hideModalMenu = () => {
    setLgShow(true);
    hideMenu();
  };
  const _hideModalMenuOnly = () => {
    hideMenu();
  };
  function setPositionAfterResized(){
    // if(canvas.isDrawingMode == true)
    // {

    // }
    var canvasdata=document.getElementById("canvas");
    var ppp=document.getElementsByClassName("flexible-modal-drag-area");
    var ppp1=document.getElementsByClassName("flexible-modal-drag-area-left");
    var canvas2=document.getElementsByClassName("upper-canvas"); 
    //console.log(canvasdata);
    // console.log("flexible-modal-drag-area"+ppp);
    // console.log("flexible-modal-drag-area-left"+ppp1);
    //console.log(canvas2);
    
    // canvasdata.height=parseInt(document.querySelector(".flexible-modal").style.height);
    // canvasdata.width=parseInt(document.querySelector(".flexible-modal").style.width);
    canvasdata.style.width = (parseInt(document.querySelector(".flexible-modal").style.width)-10)+
    "px";
    canvasdata.style.height = (parseInt(document.querySelector(".flexible-modal").style.height)-100)+'px';
    // canvas2[0].height=parseInt(document.querySelector(".flexible-modal").style.height);
    // canvas2[0].width=parseInt(document.querySelector(".flexible-modal").style.width);
    canvas2[0].style.width = (parseInt(document.querySelector(".flexible-modal").style.width)-10)+
    "px";
    canvas2[0].style.height = (parseInt(document.querySelector(".flexible-modal").style.height)-100)+'px';
  }
  const accordionMenu = () => {
    setAccordion(!accordion);
    console.log("accordion", accordion);

    let menuContant = document.getElementById("menu-contant");
    if (accordion) {
      menuContant.style.display = "none";
    } else {
      menuContant.style.display = "block";
    }
  };
  function LogOut() {
    // localStorage.clear();
    // alert("thank you for the session ");
    // navigate("/login")

    document.getElementById("pop-msg").style.display = "block";
  }
  function hide() {
    document.getElementById("pop-msg").style.display = "none";
  }
  const onSuccess = () => {
    console.log("Logout Made Successfully");
    // alert('Logout Made Successfully ✌');
    resetdivonLogout();
    localStorage.clear();
    navigate("/thankyou");
  };
  function resetdivonLogout() {
    localStorage.clear();
  }
  //For Save user login time and user spent time  ----start
  var timer;
  const handleClick =async (event) => {

        clearTimeout(timer);
        if (event.detail === 1) {

          timer = setTimeout(async () => {
    
    
    
            let GoogleLogin = localStorage.getItem("GLogin");
    
            let NormalLogin = localStorage.getItem("NLogin");
    
            console.log("GoogleLogin", typeof GoogleLogin, GoogleLogin)
    
            console.log("NormalLogin", typeof NormalLogin, NormalLogin)
    
    
    
            if (GoogleLogin === "1") {
    
              let data;
              let UserAdress;
    
              await fetch("https://api.ipify.org?format=json")
                .then(response => response.json())
                .then((responseJson) => {
                  data = responseJson
                  console.log("Response of IP ======>", responseJson)
                }).catch((error) => {
                  console.log("error", error)
                })
              if(data!=null)
              {
              await fetch(`https://ipapi.co/${data.ip}/json/`)
                .then(response => response.json())
                .then((responseJson) => {
                  UserAdress = responseJson;
                  console.log("response of adresss:=====>", responseJson)
                }).catch((error) => {
                  console.log("error", error)
                })
    
    
    
              let GoogleDetails = JSON.parse(localStorage.getItem("GoogleDetails"))
    
              console.log("GoogleDetails", typeof GoogleDetails, GoogleDetails)
    
    
              await fetch("https://learnupcenterapi.azurewebsites.net/api/UserLoginStatus/GmailUserLoginStatus", {
                method: 'post',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  FullName: GoogleDetails.name,
                  Email: GoogleDetails.email,
                  City: UserAdress.city,
                  CountryName: UserAdress.country_name,
                  CountryCode: UserAdress.country_code,
                  country_capital: UserAdress.country_capital,
                  PostalCode: UserAdress.postal,
                  region: UserAdress.region,
                  region_code: UserAdress.region_code
    
                })
              })
            }
            }
            if (NormalLogin === "1") {
              let userID = localStorage.getItem("UserId");
              let RoleID = localStorage.getItem("RoleId");
              let name = localStorage.getItem("Name");
              console.log("normal login", userID, RoleID, name)
    
              let data;
              let UserAdress;
    
              await fetch("https://api.ipify.org?format=json")
                .then(response => response.json())
                .then((responseJson) => {
                  data = responseJson
                  console.log("Response of IP ======>", responseJson)
                }).catch((error) => {
                  console.log("error", error)
                })
                if(data!=null)
                {
              await fetch(`https://ipapi.co/${data.ip}/json/`)
                .then(response => response.json())
                .then((responseJson) => {
                  UserAdress = responseJson;
                  console.log("response of adresss:=====>", responseJson)
                }).catch((error) => {
                  console.log("error", error)
                })
    
              let result = await fetch("https://learnupcenterapi.azurewebsites.net/api/UserLoginStatus/NormalUserLoginStatus", {
                method: 'post',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  FullName: name,
                  UserId: userID,
                  RoleID: RoleID,
                  City: UserAdress.City,
                  CountryName: UserAdress.CountryName,
                  CountryCode: UserAdress.CountryCode,
                  country_capital: UserAdress.country_capital,
                  PostalCode: UserAdress.PostalCode,
                  region: UserAdress.region,
                  region_code: UserAdress.region_code
    
                })
              })
              console.log("result", result)
            }
            }
          }, 200)
        } else if (event.detail === 2) {
    
          let GoogleLogin = localStorage.getItem("GLogin");
          let NormalLogin = localStorage.getItem("NLogin");
          console.log("GoogleLogin", typeof GoogleLogin, GoogleLogin)
          console.log("NormalLogin", typeof NormalLogin, NormalLogin)
          if (GoogleLogin === "1") {
    
            let GoogleDetails = JSON.parse(localStorage.getItem("GoogleDetails"))
            console.log("GoogleDetails", typeof GoogleDetails, GoogleDetails)
    
            let data;
            let UserAdress;
    
            await fetch("https://api.ipify.org?format=json")
              .then(response => response.json())
              .then((responseJson) => {
                data = responseJson
                console.log("Response of IP ======>", responseJson)
              }).catch((error) => {
                console.log("error", error)
              })
              if(data!=null)
              {
            await fetch(`https://ipapi.co/${data.ip}/json/`)
              .then(response => response.json())
              .then((responseJson) => {
                UserAdress = responseJson;
                console.log("response of adresss:=====>", responseJson)
              }).catch((error) => {
                console.log("error", error)
              })
    
    
            await fetch("https://learnupcenterapi.azurewebsites.net/api/UserLoginStatus/GmailUserLoginStatus", {
              method: 'post',
    
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                FullName: GoogleDetails.name,
                Email: GoogleDetails.email,
                City: UserAdress.City,
                CountryName: UserAdress.CountryName,
                CountryCode: UserAdress.CountryCode,
                country_capital: UserAdress.country_capital,
                PostalCode: UserAdress.PostalCode,
                region: UserAdress.region,
                region_code: UserAdress.region_code
    
              })
            })
          }
          }
          if (NormalLogin === "1") {
            let userID = localStorage.getItem("UserId");
            let RoleID = localStorage.getItem("RoleId");
            let name = localStorage.getItem("Name");
            console.log("normal login", userID, RoleID, name)
    
            let data;
            let UserAdress;
    
            await fetch("https://api.ipify.org?format=json")
              .then(response => response.json())
              .then((responseJson) => {
                data = responseJson
                console.log("Response of IP ======>", responseJson)
              }).catch((error) => {
                console.log("error", error)
              })
              if(data!=null)
              {
            await fetch(`https://ipapi.co/${data.ip}/json/`)
              .then(response => response.json())
              .then((responseJson) => {
                UserAdress = responseJson;
                console.log("response of adresss:=====>", responseJson)
              }).catch((error) => {
                console.log("error", error)
              })
    
    
            let result = await fetch("https://learnupcenterapi.azurewebsites.net/api/UserLoginStatus/NormalUserLoginStatus", {
              method: 'post',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                FullName: name,
                UserId: userID,
                RoleID: RoleID,
                City: UserAdress.City,
                CountryName: UserAdress.CountryName,
                CountryCode: UserAdress.CountryCode,
                country_capital: UserAdress.country_capital,
                PostalCode: UserAdress.PostalCode,
                region: UserAdress.region,
                region_code: UserAdress.region_code
    
              })
            })
            //      console.log("result", result)
          }
          //console.log("DOUBLE CLICK");
        }
        }

    }
  //  end 
  return (
    <div onClick={handleClick}>
      <ReactModal
        initWidth={700}
        initHeight={330}
        minHeight={330}
        minWidth={400}
        a
        disableKeystroke={true}
        // onFocus={() => console.log("Modal is clicked")}
        onFocus={() => setPositionAfterResized()}
        className={"my-modal-custom-class"}
        onRequestClose={() => setLgShow(false)}
        isOpen={lgShow}
        default={{     x: 100,     y: 100,     width: 320,     height: 330,   }}
      >
        <div className="writing-txt">
          <h3>Written Response</h3>
          <span className="close-modal">
            <a className="close-btn modal-btn" onClick={SetDefaultPositions}>
              &times;
            </a>
          </span>
        </div>

        <div className="body">
          <div className="container">
            <div className="row mt-4">
              {value == false && <WritingExData />}
            </div>
          </div>
        </div>
      </ReactModal>
      <div id="pop-msg" className="modal">
        <h6 className="mb-4">Are you sure you want to log out?</h6>

        <button
          type="button"
          onClick={hide}
          className="cancelbtn btn btn-danger "
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={() => {
            localStorage.clear();
            resetdivonLogout();
            navigate("/thankyou");
          }}
          className="cancelbtn btn btn-success  px-4 mx-4"
        >
          ok
        </button>
      </div>
      <div>
        <span id="mainbox" onClick={showMenu}>
          &#9776;
        </span>
      </div>

      <div className="sidemenu" id="menu" style={{ overflowY: "auto" }}>
        <div>
          <img src={Learnup} alt="Learnup Logo" className="sidemenu-logo" />
          <span>
            <a className="close-btn" onClick={hideMenu}>
              &times;
            </a>
          </span>
        </div>
        <div className="sidemenu-list">
          <ul style={{ padding: "0px", marginTop: "5px" }}>
            <li className="menu-action">
              {/* <a href="#">Assessment</a> */}
              <a href="#">Spell Sounds (mouth)</a>
              
            </li>
            <li className="menu-action">
              {/* <a href="#">Reading Lessons</a> */}
              <a href="#">Read Sounds (point)</a>
            </li>
            <li className="menu-action">
              {/* <a href="#">Spelling Sounds</a> */}
              <a href="#">New Concept (teach)</a>
            </li>
            <li className="menu-action">
              {/* <a href="#">Reading Sounds</a> */}
              <a href="#">Spell Syllables (mouth)</a>
            </li>
            <li className="menu-action">
              {/* <a href="#">Reading Syllable</a> */}
              <a href="#">Read Syllables (point)</a>
            </li>
            <li className="menu-action">
              {/* <a href="#">Spelling Syllable</a> */}
              <a href="#">Spell Words (mouth)</a>
            </li>
            <li className="menu-action">
              {/* <a href="#">Reading Words</a> */}
              <a href="#">Read Words (point)</a>
            </li>
            {/* <li className="menu-action">
              
              <a href="#">Spelling Words </a>
            </li> */}

            {/* <li><a href="#">Written Response </a></li> */}
            <li className="menu-action">
              <a onClick={_hideModalMenu} className="txt-color">
              Written Response{" "}
              </a>
            </li>
            <li
              className="menu-action"
              id="menu-action"
              onClick={accordionMenu}
            >
              <a className="setColorInHamberger">Boards </a>
              <span className="setColorInHamberger"> &#8964;</span>
            </li>
            <div id="menu-contant">
              {location.pathname !== "/home" ? (
                <li style={{ paddingLeft: "50px" }}>
                  <Link onClick={_hideModalMenuOnly} to="/home">
                  Default{" "}
                  </Link>
                </li>
              ) : null}
              {location.pathname !== "/galaxy-board" ? (
                <li style={{ paddingLeft: "50px" }}>
                  <Link onClick={_hideModalMenuOnly} to="/galaxy-board">
                    Vowel Galaxy{" "}
                  </Link>
                </li>
              ) : null}
              {location.pathname !== "/start-board" ? (
                <li style={{ paddingLeft: "50px" }}>
                  <Link onClick={_hideModalMenuOnly} to="/start-board">
                  Start 1{" "}
                  </Link>
                </li>
              ) : null}
            </div>
            <li className="google-account">
              {" "}
              {localStorage.getItem("NLogin") === "1" && (
                <a id="NormalLogout" onClick={LogOut}>
                  Log out
                </a>
              )}
              {localStorage.getItem("GLogin") === "1" && (
                <div id="GoogleLogout" className="font-weight-bold">
                  <GoogleLogout
                    clientId={clientId}
                    buttonText="Logout"
                    onLogoutSuccess={onSuccess}
                  ></GoogleLogout>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    
    </div>
  );
};

export default SideMenu;
