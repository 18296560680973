import React, { FC, useRef } from "react";
import "dotenv/config";
import { useMemo, useEffect, useState } from "react";
import styles from "../Whiteboard/Whiteboard.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import "../Tiles/Tiles";
import Interactable from "../../contexts/Interactable";
import { v4 as uuid } from "uuid";
import interact from "interactjs";
import styled from "styled-components";
import { isGeneratorFunction } from "util/types";
import "bootstrap/dist/css/bootstrap.css";
import "../AdminDashBoard/AdminDashBoard.css";
import { useScreenshot } from "use-screenshot-hook";
import CloseIcon from "../pages/close";
import { Helmet } from "react-helmet";
import { GoogleLogout } from "react-google-login";
import LoginPage from "../pages/LoginPage";
import $ from "jquery";
import { Console } from "console";
// import gapi from "@type/gapi";
import { gapi } from "gapi-script";
import { start } from "repl";
import WhiteBoardJson from "../../whiteboard.json";
import BackBoardJson from "../../BackSideBoard.json";
import { Link } from "react-router-dom";
import Learnup from "../../assets/images/v2_7.png";
import "../../contexts/position-style.css";
import ReactModal from "react-modal-resizable-draggable";
import WritingExData from "../WritingExData";
import Dropdown from "react-bootstrap/Dropdown";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from "@mui/material/Switch";
import { BsFillVolumeDownFill } from "react-icons/bs";

const options = [
  { value: "Writing", label: "Writing" },
  { value: "Typing", label: "Typing" },
  { value: "", label: "" },
];

declare global {
  interface window {
    n;
    React: any;
  }
}

const position = { x: 0, y: 0 };
let LastDraggedX = 0;
//Drag and drop textBox start

// this function is used later in the resizing and gesture demos

//end
// interact('.drag-drop-galaxy').on('tap', function (event) {
//   event.currentTarget.classList.toggle('switch-bg')
//   event.preventDefault()
// })
// const clientId ='478729096121-acrvqi9nptdhg2j8jsbnnoomjjnr5g7q.apps.googleusercontent.com'
const clientId =
  "478729096121-acrvqi9nptdhg2j8jsbnnoomjjnr5g7q.apps.googleusercontent.com";
const API_Key = "AIzaSyCeMVQIgdS6PqmmW8sKwK1IWBzOmmbUVOc";
const Client_secret = "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";
const scope = "https://www.googleapis.com/auth/drive";
function noOverlap1(event) {
  var dx = event.dx;
  var dy = event.dy;
  var overlapElements = document.querySelectorAll(".drag-drop-galaxy");

  //just for flagging when the target would overlap another element
  var overlap = false;
  for (let i = 0; i < overlapElements.length; i++) {
    var targetDims = event.target.getBoundingClientRect();
    var overlapElementDims = overlapElements[i].getBoundingClientRect();
    overlapElements[i].classList.remove("highlightTiles");
    //make sure the element doesn't look at itself..
    if (overlapElements[i] != event.target) {
      //checks if the target "doesn't" overlap
      if (
        targetDims.right + dx < overlapElementDims.left + 3 ||
        targetDims.left + 1 + dx > overlapElementDims.right - 3 ||
        targetDims.top + 1 + dy > overlapElementDims.bottom ||
        targetDims.bottom + dy < overlapElementDims.top + 1
      ) {
        //Basically, the target element doesn't overlap the current
        //element in the HTMLCollection, do nothing and go to the
        //next iterate
      } else {
        //This is if the target element would overlap the current element

        //set overlap to true and break out of the for loop to conserve time.

        overlap = true;
        var rectangles_L = overlapElementDims.left;
        var rectangels_T = overlapElementDims.top;
        var lastDragged_L = targetDims.left;
        var lastDragged_T = targetDims.top;
        var xDistance = rectangles_L - lastDragged_L;
        var yDistance = rectangels_T - lastDragged_T;
        var roundUp_X = Math.ceil(xDistance);
        var roundUp_Y = Math.ceil(yDistance);

        var move_X = '"' + "+=" + roundUp_X + "px" + '"';
        var move_Y = "+=" + roundUp_Y + "px";
        console.log(overlapElementDims.width);

        console.log("move_X = " + roundUp_X);
        console.log("move_Y = " + roundUp_Y);
        var parseXoverValue = 0;
        var parseXMoveValue = 0;
        if (roundUp_X < 0) {
          parseXoverValue =
            parseInt(event.target.dataset.x) +
            parseInt(targetDims.width) +
            roundUp_X;
          parseXMoveValue = parseInt(targetDims.width) + roundUp_X;
        } else {
          parseXoverValue =
            parseInt(event.target.dataset.x) +
            parseInt(targetDims.width) +
            roundUp_X;
          parseXMoveValue = parseInt(targetDims.width) + roundUp_X;
        }

        event.target.style.webkitTransform = event.target.style.transform =
          "translate(" +
          parseXoverValue +
          "px, " +
          event.target.dataset.y +
          "px)";
        event.rect.left = parseInt(event.rect.left) + parseXMoveValue;
        event.rect.right = event.rect.right + parseXMoveValue;
        event.rect["data_x"] = parseXoverValue;
        event.rect["data_y"] = event.target.dataset.y;
        event.target.dataset.x = parseXoverValue;
        event.rect.left =
          event.rect.left + overlapElementDims.width + 10 + "px";
        break;
        overlapElements = document.querySelectorAll(".drag-drop-galaxy");
        i = 0;
      }
    }
  }

  // if (overlap === true) {

  //   console.log("hello overlap after drag end")
  //   //then reset x and y
  //   event.target.x = 0;
  //   event.target.y = 0;
  //   const parse_array = event.target.id.split('_');
  //   // let urld=parse_array[0];
  //   if (parse_array[2]) {
  //     let p1 = event.target.removeAttribute("style");
  //     let p2 = event.target.removeAttribute("data-x");
  //     let p3 = event.target.removeAttribute("data-y");
  //     // let p4=event.target.removeAttribute("left");
  //     // let p5=event.target.removeAttribute("right");
  //     // let p6=event.target.removeAttribute("top");
  //     event.target.style.position = "absolute";
  //     // element.style.position = "relative";
  //     event.target.style.left = event.x;
  //     event.target.style.top = event.y;
  //     // event.target.style.fontSize='15px';
  //     // event.target.style.width = '4.125rem'
  //   }
  //   else {
  //     let p1 = event.target.removeAttribute("style");
  //     let p2 = event.target.removeAttribute("data-x");
  //     let p3 = event.target.removeAttribute("data-y");
  //     let p4 = event.target.removeAttribute("left");
  //     let p5 = event.target.removeAttribute("right");
  //     let p6 = event.target.removeAttribute("top");
  //   }

  // }
}

function CheckOverlap(event, overlapElements) {
  var dx = event.dx;
  var dy = event.dy;

  //just for flagging when the target would overlap another element
  var overlap = false;
  var targetDims = event.target.getBoundingClientRect();
  var j = 0;
  for (let i = 0; i < overlapElements.length; i++) {
    var overlapElementDims = overlapElements[i].getBoundingClientRect();
    j = i;
    //make sure the element doesn't look at itself..
    if (overlapElements[i] != event.target) {
      //checks if the target "doesn't" overlap
      if (
        targetDims.right + dx < overlapElementDims.left + 2 ||
        targetDims.left + 1 + dx > overlapElementDims.right - 2 ||
        targetDims.top + 1 + dy > overlapElementDims.bottom ||
        targetDims.bottom + dy < overlapElementDims.top + 1
      ) {
        //Basically, the target element doesn't overlap the current
        //element in the HTMLCollection, do nothing and go to the
        //next iterate
        overlapElements[i].classList.remove("highlightTiles");
      } else {
        //This is if the target element would overlap the current element

        //set overlap to true and break out of the for loop to conserve time.

        overlap = true;
        overlapElements[i].classList.add("highlightTiles");
      }
    }
  }
}


var soundWhooshGB = true;
var soundTilesGB = true;
//for drag
interact(".drag-drop-galaxy")
  .draggable({
    modifiers: [
      interact.modifiers.snap({
        targets: [interact.snappers.grid({ x: 2, y: 1 })],
        range: Infinity,
        relativePoints: [{ x: 0, y: 0 }],
      }),
      interact.modifiers.restrict({
        //restriction: element.parentNode,
        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
        endOnly: false,
      }),
    ],
    inertia: true,
  })
  .on("dragmove", function(event) {
    console.log("galaxy");
    const target = event.target;
    const dx = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    const dy = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
    target.style.webkitTransform = target.style.transform =
      "translate(" + dx + "px, " + dy + "px)";
    target.setAttribute("data-x", dx);
    target.setAttribute("data-y", dy);
    if (event.client.x > LastDraggedX) LastDraggedX = event.client.x;
    var element = document.querySelectorAll(".drag-drop-galaxy");
    CheckOverlap(event, element);
  })
  .on("dragend", function(event) {
    event.target.setAttribute("left", event.rect.left);
    event.target.setAttribute("right", event.rect.right);
    event.target.setAttribute("top", event.rect.top);
    event.target.setAttribute("bottom", event.rect.bottom);
    console.log("event.relatedTarget.id", event.currentTarget.id);
    console.log(event.rect);
    console.log(event.target.dataset.x);
    console.log(event.target.dataset.y);
    let id = event.currentTarget.id;
    const parse_array = id.split("_");
    let path_dir = parse_array[2];
    // if (parse_array[3]) {
    if (path_dir == "frontofboard") {
      if (
        LastDraggedX >
        document.getElementById("Galaxywhiteboard").offsetWidth +
          document.getElementById("Galaxywhiteboard").offsetLeft
      ) {
        //  console.log("hello");
        const parse_array = event.currentTarget.id.split("_");
        let urld = parse_array[0];
        let path_char = parse_array[1];
        if (parse_array[4]) {
          console.log(parse_array[3]);
          event.target.remove();
        } else {
          // localStorage.setItem("hide_"+event.currentTarget.id, JSON.stringify(true));
          // document.getElementById(event.currentTarget.id).style.visibility = 'hidden';
          //localStorage.removeItem(event.currentTarget.id);
          event.target.removeAttribute("style");
          event.target.removeAttribute("data-x");
          event.target.removeAttribute("data-y");
          event.target.removeAttribute("left");
          event.target.removeAttribute("right");
          event.target.removeAttribute("top");
        }
        let audiofileurl = process.env.PUBLIC_URL + "removesound.mp3";
        let payeaudio = new Audio(audiofileurl);
        if(soundWhooshGB){
          payeaudio.play();
        } 
        LastDraggedX = 0;
      } else {
        //noOverlap1(event);
        clonediv(event);
      }
    }
    // else if (path_dir == "backofboard") {
    //   if (
    //     LastDraggedX >
    //     document.getElementById("backwhiteboard").offsetWidth +
    //       document.getElementById("backwhiteboard").offsetLeft
    //   ) {
    //     //  console.log("hello");
    //     const parse_array = event.currentTarget.id.split("_");
    //     let urld = parse_array[0];
    //     let path_char = parse_array[1];
    //     if (parse_array[3]) {
    //       console.log(parse_array[3]);
    //       event.target.remove();
    //     } else {
    //       // localStorage.setItem("hide_"+event.currentTarget.id, JSON.stringify(true));
    //       // document.getElementById(event.currentTarget.id).style.visibility = 'hidden';
    //       localStorage.removeItem(event.currentTarget.id);
    //       event.target.removeAttribute("style");
    //       event.target.removeAttribute("data-x");
    //       event.target.removeAttribute("data-y");
    //       event.target.removeAttribute("left");
    //       event.target.removeAttribute("right");
    //       event.target.removeAttribute("top");
    //     }
    //     let audiofileurl = process.env.PUBLIC_URL + "removesound.mp3";
    //     let payeaudio = new Audio(audiofileurl);
    //     payeaudio.play();
    //     LastDraggedX = 0;
    //   } else {
    //     noOverlap1(event);
    //     clonediv(event);
    //   }
    // }
  })
  .on("doubletap", function(event) {
    clickchar(event.currentTarget.id);
  })
  .on("hold", function(event) {
    const { currentTarget, interaction } = event;
    let element = currentTarget;
    element = currentTarget.cloneNode(true);
    let id = currentTarget.id;
    const parse_array = id.split("_");
    element.id = element.id + "_" + uuid();
    if (parse_array[4]) {
      element.style.position = "absolute";
      element.clientX = event.clientX + 25;
      element.clientY = event.clientY + 25;
      let sleft = parseFloat(currentTarget.style.left);
      let stop = parseFloat(currentTarget.style.top);
      element.style.left = sleft + parseFloat("50") + "px"; //(20+event.clientX+10);
      element.style.top = stop + parseFloat("50") + "px"; //(20+event.clientY+10);
    } else {
      if (event.target.style.transform) {
        element.style.transform = "";
        element.setAttribute("data-x", 0);
        element.setAttribute("data-y", 0);
      }

      element.style.position = "absolute";
      element.clientX = event.clientX + 25;
      element.clientY = event.clientY + 25;
      element.style.left = event.clientX + 25 + "px"; //(20+event.clientX+10);
      element.style.top = event.clientY + 25 + "px"; //(20+event.clientY+10);
    }
    console.log(event);
    var container;
    container = document.getElementById("Galaxywhiteboard");
    if (container) {
      container && container.appendChild(element);
    }
  });

let clickchar = (id) => {
  console.log(id);
  // let urld=id.replace("_drop","");
  const parse_array = id.split("_");
  let urld = parse_array[1];
  let path_dir = parse_array[2];
  let path_char = parse_array[3];
  console.log(urld);
  console.log(path_char);
  if (urld == "wh") urld = "wh-";
  if (urld == "con") urld = urld + "-";
  if (urld == "true") urld = "ture";
  // console.log('url', urld);
  if (urld == "le") urld = "tle";
  // console.log('url', urld);

  let audiofileurl =
    process.env.PUBLIC_URL +
    "/audio/" +
    path_dir +
    "/" +
    path_char +
    "/" +
    urld +
    ".mp3";
  let payeaudio = new Audio(audiofileurl);
  if(soundTilesGB){
    payeaudio.play();
  } 
};
function resetdivonLogout() {
  localStorage.clear();
}

function diffrence(a, b) {
  return a > b ? a - b : b - a;
}

function diffrenceFortop(a, b) {
  return a - b;
}
function clonediv(event) {
  console.log(event.currentTarget.id);
  let pppp = event.currentTarget.id;
  var element = document.querySelectorAll(".drag-drop-galaxy");
  let j = 0;
  for (let i = 0; i < element.length; i++) {
    var t = [];
    if (element[i].id != event.currentTarget.id) {
      let left = element[i].getAttributeNode("left");
      let right = element[i].getAttributeNode("right");
      var targetDims = event.target.getBoundingClientRect();
      var magnaticElementDims = element[i].getBoundingClientRect();
      var diffeleft = diffrence(targetDims.left, magnaticElementDims.right);

      var differight = diffrence(targetDims.right, magnaticElementDims.left);
      if (
        diffeleft <= 10 &&
        diffrence(targetDims.top, magnaticElementDims.top) <= 10
      ) {
        console.log("clone left ");
        var targetDims = event.target.getBoundingClientRect();
        var magnaticElementDims = element[i].getBoundingClientRect();
        var leftsidemagnatic = diffrence(
          targetDims.left,
          magnaticElementDims.right
        );
        var topsideposition = diffrenceFortop(
          magnaticElementDims.top,
          targetDims.top
        );
        j = j + 1;
        let id = pppp;
        let top = element[i].getAttributeNode("top");
        let bottom = element[i].getAttributeNode("bottom");
        event.target.style.webkitTransform = event.target.style.transform =
          "translate(" +
          (event.target.dataset.x - leftsidemagnatic - 2) +
          "px, " +
          (event.target.dataset.y - 1 + topsideposition + 1) +
          "px)";
        // event.rect.top=targetDims.top;
        // event.rect.left = event.rect.left;
        // event.rect.right = event.rect.right;
        event.rect.top = targetDims.top - 1 + topsideposition + 1;
        event.rect.buttom = targetDims.buttom - 1 + topsideposition + 1;
        event.rect.left = event.rect.left - leftsidemagnatic - 2;
        event.rect.right = event.rect.right - leftsidemagnatic;
        event.rect["data_x"] = event.target.dataset.x - leftsidemagnatic - 2;
        event.rect["data_y"] = event.target.dataset.y - 1 + topsideposition + 1;
        event.rect["eventName"] = event.target.innerHTML;
        event.rect["eventClass"] = event.target.className;
        localStorage.setItem(id, JSON.stringify(event.rect));
      } else if (
        differight <= 10 &&
        diffrence(targetDims.top, magnaticElementDims.top) <= 10
      ) {
        j = j + 1;
        let id = pppp;
        let top = element[i].getAttributeNode("top");
        let bottom = element[i].getAttributeNode("bottom");
        console.log("clone right ");
        console.log(differight);
        var targetDims = event.target.getBoundingClientRect();
        var magnaticElementDims = element[i].getBoundingClientRect();
        var rightsidemagnatic = diffrence(
          targetDims.right,
          magnaticElementDims.left
        );
        var topsideposition = diffrenceFortop(
          magnaticElementDims.top,
          targetDims.top
        );
        event.target.style.webkitTransform = event.target.style.transform =
          "translate(" +
          (event.target.dataset.x - 1 + rightsidemagnatic + 3) +
          "px, " +
          (event.target.dataset.y - 1 + topsideposition + 1) +
          "px)";
        // event.rect.top=targetDims.top;
        // event.rect.left = event.rect.left;
        // event.rect.right = event.rect.right;
        event.rect.top = targetDims.top - 1 + topsideposition + 1;
        event.rect.buttom = targetDims.buttom - 1 + topsideposition + 1;
        event.rect.left = event.rect.left - 1 + rightsidemagnatic + 3;
        event.rect.right = event.rect.right - 1 + rightsidemagnatic + 3;
        event.rect["data_x"] =
          event.target.dataset.x - 1 + rightsidemagnatic + 3;
        event.rect["data_y"] = event.target.dataset.y - 1 + topsideposition + 1;
        // event.rect.top = event.rect.top-1+topsideposition+1;
        event.rect["eventName"] = event.target.innerHTML;
        event.rect["eventClass"] = event.target.className;
        localStorage.setItem(id, JSON.stringify(event.rect));
      }
    }
  }
  if (j == 0) {
    // event.target.style.marginLeft='0px';
    // event.target.style.marginRight='0px';
    let id = pppp;
    event.rect["data_x"] = event.target.dataset.x;
    event.rect["data_y"] = event.target.dataset.y;
    event.rect["eventName"] = event.target.innerHTML;
    event.rect["eventClass"] = event.target.className;
    localStorage.setItem(id, JSON.stringify(event.rect));
  }
}

function FrontBoard() {
  var frontboard = document.getElementById("frontwhiteboard");
  var backboard = document.getElementById("backwhiteboard");
  var changebtntext = document.getElementById("hidefrontbutton");
  var disablebtnback = document.getElementById("hidebackbutton");
  if (frontboard.style.display === "none") {
    frontboard.style.display = "block";
    changebtntext.innerHTML = "Hide Front";
    document.getElementById("Maindiv").style.height = "200vh";
    disablebtnback.removeAttribute("disabled");
  } else {
    frontboard.style.display = "none";
    changebtntext.innerHTML = "UnHide Front";
    backboard.style.display = "block";
    document.getElementById("Maindiv").style.height = "100vh";
    disablebtnback.setAttribute("disabled", "true");
  }
}
function BackBoard() {
  var backboard = document.getElementById("backwhiteboard");
  var frontboard = document.getElementById("frontwhiteboard");
  var changebtntext = document.getElementById("hidebackbutton");
  var disablebtnfront = document.getElementById("hidefrontbutton");
  if (backboard.style.display === "none") {
    backboard.style.display = "block";
    changebtntext.innerHTML = "Hide Back";
    disablebtnfront.removeAttribute("disabled");
    document.getElementById("Maindiv").style.height = "200vh";
    // disablebtn.setAttribute('disabled','true');
  } else {
    backboard.style.display = "none";
    changebtntext.innerHTML = "UnHide Back";
    frontboard.style.display = "block";
    document.getElementById("Maindiv").style.height = "100vh";
    disablebtnfront.setAttribute("disabled", "true");
  }
}

const createFileName = (extension = "", ...names) => {
  if (!extension) {
    return "";
  }
  return `${names.join("")}.${extension}`;
};
interface WhiteboardProps {}
// const Whiteboard: FC<WhiteboardProps> = () => (
const GalaxyBoard: FC<WhiteboardProps> = () => {
  const [lgShow, setLgShow] = useState(false);
  const [valueState, setValueState] = useState("");
  const [valueChnageState, setValueChangeState] = useState("");
  const [value, setValue] = useState(false);
  const [selected, setSelected] = React.useState(false);

  const getInputValue = (event) => {
    const userValue = event.target.value;
    setValueChangeState(userValue);
    // console.log(userValue);
  };
  var soundWhooshGalaxyB = localStorage.getItem('soundWhooshGB');
  var soundTilesGalaxyB = localStorage.getItem('soundTilesGB');
    if(soundWhooshGalaxyB === 'true'){
      soundWhooshGB = true
    }
    else{
      soundWhooshGB = false
    }
    if(soundTilesGalaxyB === 'true'){
      soundTilesGB = true
    }else{
      soundTilesGB = false
    }

  const ref = useRef(null);
  const { image, takeScreenshot, isLoading, clear } = useScreenshot();
  const download = (image, { name = "LearnUp", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    const current = new Date();
    const date = `${current.getDate()}-${current.getMonth() +
      1}-${current.getFullYear()} ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
    name = name + date.toString();
    a.download = createFileName(extension, name);

    a.click();
  };
  const myStyle = {
    height: "50%",
    width: "95%",
    backgroundColor: "#ffffff",
    // margin:'auto'
  };
  const downloadScreenshot = () => takeScreenshot(ref.current).then(download);
  const navigate = useNavigate();

  function resetdiv() {
    console.log("Reset");
    var element = document.querySelectorAll(".drag-drop-galaxy");

    for (let i = 0; i < element.length; i++) {
      console.log(element[i]);
      localStorage.removeItem(element[i].id);
      // element[i].removeAttribute("style");
      var getid = document.getElementById(element[i].id);
      localStorage.removeItem(element[i].id);
      // element[i].removeAttribute("style");
      getid.style.transform = "";
      element[i].removeAttribute("data-x");
      element[i].removeAttribute("data-y");
      element[i].removeAttribute("left");
      element[i].removeAttribute("right");
      element[i].removeAttribute("top");
      const parse_array = element[i].id.split("_");
      let urld = parse_array[0];
      if (parse_array[4]) {
        console.log(element[i].id);
        var t = document.getElementById(element[i].id);
        t.remove();
      }
      localStorage.removeItem(element[i].id);
    }
    // window.location.reload();
  }

  useEffect(() => {

        var soundWhooshGalaxyB = localStorage.getItem('soundWhooshGB');
        var soundTilesGalaxyB = localStorage.getItem('soundTilesGB');
        if(soundWhooshGalaxyB === 'true'){
                soundWhooshGB = true
        }else{
                soundWhooshGB = false
        }
        if(soundTilesGalaxyB === 'true'){
              soundTilesGB = true
        }else{
              soundTilesGB = false
        }

    //google doc //
    // function start() {
    //   gapi.client.init({
    //     apiKey: API_Key,
    //     clientId: clientId,
    //     scope: scope,
    //   });
    // }
    // gapi.load("client:auth2", start);

    const renderGoogleButton = localStorage.getItem("GLogin");
    console.log("GoogleLOginButton", renderGoogleButton);
    const renderNormalLogoutButton = localStorage.getItem("NLogin");
    console.log("Normal login button", renderNormalLogoutButton);

    if (renderGoogleButton == "1") {
      document.getElementById("GoogleLogout").style.display = "block";
    }

    if (renderNormalLogoutButton == "1") {
      document.getElementById("NormalLogout").style.display = "block";
    }

    //google doc end//
    var getAllTiles = document.querySelectorAll(".drag-drop-galaxy");
    var values = [],
      LocalStorageitemsKeys = Object.keys(localStorage),
      i = LocalStorageitemsKeys.length;
    // for (var l = 1; l <= LocalStorageitemsKeys.length; l++) {
    while (i--) {
      let divdata = JSON.parse(localStorage.getItem(LocalStorageitemsKeys[i]));
      console.log(typeof divdata);
      console.log(divdata["left"]);
      console.log(divdata["right"]);
      console.log(divdata["top"]);
      console.log(divdata);
      // console.log(divdata[""]);

      for (var g = 0; g < getAllTiles.length; g++) {
        //console.log(getAllTiles[g])
        const parse_array = LocalStorageitemsKeys[i].split("_");
        if (LocalStorageitemsKeys[i] == getAllTiles[g].id) {
          var elem = document.getElementById(getAllTiles[g].id);
          elem.style.transform =
            "translate(" +
            divdata["data_x"] +
            "px, " +
            divdata["data_y"] +
            "px)";
          getAllTiles[g].setAttribute("left", divdata["left"]);
          getAllTiles[g].setAttribute("right", divdata["right"]);
          getAllTiles[g].setAttribute("top", divdata["top"]);
          getAllTiles[g].setAttribute("data-x", divdata["data_x"]);
          getAllTiles[g].setAttribute("data-y", divdata["data_y"]);
        } else if (
          parse_array[0] +
            "_" +
            parse_array[1] +
            "_" +
            parse_array[2] +
            "_" +
            parse_array[3] ==
            getAllTiles[g].id &&
          parse_array[4]
        ) {
          var newDiv = document.createElement("div");
          newDiv.setAttribute("id", LocalStorageitemsKeys[i]);
          //newDiv.style.transform = "translate(" + divdata["data_x"] + "px, " + divdata["data_y"] + "px)";
          newDiv.setAttribute("left", divdata["left"]);
          newDiv.setAttribute("right", divdata["right"]);
          newDiv.setAttribute("top", divdata["top"]);
          document.getElementById("Galaxywhiteboard").appendChild(newDiv);
          document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
            divdata["eventName"];
          document.getElementById(LocalStorageitemsKeys[i]).className =
            divdata["eventClass"];
          var elem = document.getElementById(LocalStorageitemsKeys[i]);
          elem.style.position = "absolute";
          elem.style.left = divdata["left"] + "px";
          elem.style.top = divdata["top"] + "px";
          elem.style.transform = "";
          elem.style.margin = "0px";
        }
      }
      console.log("Component mounted");
    }
    return () => {
      console.log("Component will be unmount");
    };
  }, []);

  const _soundWhooshGb = () => {
    if(soundWhooshGB === true){
          soundWhooshGB = false
          localStorage.setItem('soundWhooshGB', JSON.stringify(false));
    }else{
         soundWhooshGB = true
          localStorage.setItem('soundWhooshGB', JSON.stringify(true));
    }
}

const _soundTilesGb = () => {
  if(soundTilesGB === true){
         soundTilesGB = false
        localStorage.setItem('soundTilesGB', JSON.stringify(false))
  }else{
    soundTilesGB = true
        localStorage.setItem('soundTilesGB', JSON.stringify(true));
  }
}

  function createFile() {
    var accessToken = gapi.auth.getToken().access_token;
    var fileName = "hello" + "Notes," + new Date();
    fetch("https://docs.googleapis.com/v1/documents", {
      method: "POST",
      headers: new Headers({ Authorization: "Bearer" + accessToken }),
    })
      .then((res) => {
        return res.json();
      })
      .then(function(val) {
        console.log(val);
        console.log(val.documentId);
      });
  }
  function dragMoveListener(event) {
    var target = event.target;
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.transform = "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
  }
  interact(".dropzone").dropzone({
    // only accept elements matching this CSS selector
    // accept: "#yes-drop",
    // Require a 75% element overlap for a drop to be possible
    overlap: 0.75,

    // listen for drop related events:
    ondropactivate: function(event) {
      // add active dropzone feedback
      event.target.classList.add("drop-active");
    },
    ondragenter: function(event) {
      var draggableElement = event.relatedTarget;
      var dropzoneElement = event.target;
      console.log("event event", event);

      // feedback the possibility of a drop
      dropzoneElement.classList.add("drop-target");
      draggableElement.classList.add("can-drop");
      // draggableElement.textContent = "Dragged in";
    },
    ondragleave: function(event) {
      // remove the drop feedback style
      event.target.classList.remove("drop-target");
      event.relatedTarget.classList.remove("can-drop");
      event.relatedTarget.classList.remove("non-style-tile");
      // event.relatedTarget.textContent = "Dragged out";
      // document
      //   .getElementById("outer-dropzone")
      //   .removeChild(event.relatedTarget);
    },
    ondrop: function(event) {
      // event.relatedTarget.textContent = "Dropped";
      let dropped = event.relatedTarget;
      console.log(
        'document.getElementById("outer-dropzone").children.length',
        document.getElementById("outer-dropzone").children.length
      );
      if (document.getElementById("outer-dropzone").children.length == 1) {
        document.getElementById("outer-dropzone").appendChild(dropped);
        // event.relatedTarget.classList.remove("non-style-tile");
        dropped.classList.add("non-style-tile");
        console.log(
          "event.relatedTarget.custom-attribute",
          dropped.getAttribute("custom-attribute")
        );
        console.log(
          'document.getElementById("outer-dropzone").children[1]',
          document
            .getElementById("outer-dropzone")
            .children[1].getAttribute("custom-attribute")
        );
      } else if (
        document
          .getElementById("outer-dropzone")
          .children[1].getAttribute("custom-attribute") ==
        dropped.getAttribute("custom-attribute")
      ) {
        document.getElementById("outer-dropzone").appendChild(dropped);
        event.relatedTarget.classList.add("non-style-tile");
      }
      // event.target.appendChild(document.getElementById("testd"));
    },
    ondropdeactivate: function(event) {
      // remove active dropzone feedback
      event.target.classList.remove("drop-active");
      event.target.classList.remove("drop-target");
    },
  });

  interact(".drag-drop-galaxyp").draggable({
    inertia: true,
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: "parent",
        endOnly: true,
      }),
    ],
    autoScroll: true,
    // dragMoveListener from the dragging demo above
    listeners: { move: dragMoveListener },
  });
  interact(".dropzone").draggable({
    inertia: true,
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: "parent",
        endOnly: true,
      }),
    ],
    autoScroll: true,
    // dragMoveListener from the dragging demo above
    listeners: { move: dragMoveListener },
  });
  // const allowDrop = (event) => {
  //   event.preventDefault();
  // };
  // const drag = (event) => {
  //   event.dataTransfer.setData("text", event.target.id);
  // };
  // const drop = (event) => {
  //   event.preventDefault();
  //   var data = event.dataTransfer.getData("text");
  //   if (data == 1 || data == 2 || data == 3 || data == 4) {
  //     event.target.appendChild(document.getElementById(data));
  //   }
  // };
  const [boxForMarge, setBoxForMarge] = useState([]);
  // let boxForMarge = ["frg", "g"];
  const newBoxForMarge = () => {
    const prevBoxs = [...boxForMarge];
    console.log("test");
    prevBoxs.push(Math.random());
    setBoxForMarge(prevBoxs);
    console.log("boxForMarge", boxForMarge);
  };
  console.log("boxForMarge", boxForMarge);
  const deleteBox = (boxNum) => {
    // const deleteOneItem = boxForMarge.filter((it, index) => {
    //   console.log("index", index);
    //   console.log("idx", idx);
    //   return index !== idx;
    // });
    // setBoxForMarge(deleteOneItem);
    setBoxForMarge((prevState) => {
      return prevState.filter((e) => e !== boxNum);
    });
  };
  let printBoxForMarge = boxForMarge.map((box, index) => {
    return (
      <div
        id="outer-dropzone"
        style={{ margin: "5px" }}
        className="dropzone col-lg-2 col-md-2 col-sm-2"
        key={index}
      >
        <p
          className="close-box"
          onClick={() => {
            deleteBox(box);
          }}
        >
          &times;
        </p>
      </div>
    );
  });
  const [accordion, setAccordion] = useState(true);
  const accordionMenu = () => {
    setAccordion(!accordion);
    console.log("accordion", accordion);

    let menuContant = document.getElementById("menu-contant");
    if (accordion) {
      menuContant.style.display = "none";
    } else {
      menuContant.style.display = "block";
    }
  };
  return (
    <>
      <Helmet>
        <link rel="Stylesheet" type="text/css" href="/demo/demo.css" />
        <link
          rel="Stylesheet"
          type="text/css"
          href="/lib/wColorPicker.min.css"
        />
        <link rel="Stylesheet" type="text/css" href="/wPaint.min.css" />
        <script async type="text/javascript" src="/lib/index.js"></script>
      </Helmet>
      <div className={styles.Whiteboard}>
        <div
          className=""
          id="Maindiv"
          // style={{
          //   position: "fixed",
          //   top: "0",
          //   left: "0",
          //   right: "0",
          //   bottom: "0",
          // }}
        >
          {/* <div>
            <span id="mainbox" onClick={showMenu}>
              &#9776;
            </span>
          </div> */}

          <div className="navbar row">
            <div className="col-2"></div>
            <div className="col-5">
              <button
                className="button btn btn-light rounded mx-2"
                onClick={resetdiv}
              >
                Reset
              </button>
            </div>
            <div className=" col-2 icon-class-navbar">
            <Dropdown id="myAudio">
                <Dropdown.Toggle id="dropdown-basic">
                  <i className="icon-sound">
                    {" "}
                    <BsFillVolumeDownFill />
                  </i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <i onClick={_soundWhooshGb}>
                        <FormControlLabel control={<Switch 
                        defaultChecked={soundWhooshGB ? true : false}
                        color="warning"/>} label="Whoosh" labelPlacement="end" />
                    </i>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <i onClick={_soundTilesGb}>
                      <FormControlLabel control={<Switch
                        defaultChecked={soundTilesGB ? true : false}
                       color="warning" />} label="Tiles  " labelPlacement="end"/>
                      
                    </i>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <i
                className="bi bi-camera-fill  icon-cameras"
                onClick={() => downloadScreenshot()}
              ></i>
              <i className="bi bi-person icon-user"></i>
            </div>
          </div>

          <div
            id="Galaxywhiteboard"
            className="whiteboardgb"
            style={{ display: "block", padding: "110px 0", margin: "auto" }}
          >
            <div id="div_consonants"></div>

            {printBoxForMarge}

            <div className=" _prefixes">
              <div className="row">
                <div className="col-12">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className=" " style={{ marginLeft: "-200px" }}>
                      {/* <br /> */}
                      <div className="_pre" style={{ marginLeft: "50px" }}>
                        {WhiteBoardJson[0].R_Family._r_family.map((items) => {
                          // console.log(items.length )
                          return (
                            <>
                              <div
                                id={
                                  "Galaxy_" +
                                  items +
                                  "_" +
                                  WhiteBoardJson[0].R_Family.frontboard +
                                  "_rfamily"
                                }
                                className={`drag-drop-galaxy drag-drop-galaxy_Vowels drag-drop-galaxy-two noselect ${
                                  items.length < 3
                                    ? "tile-less-than-three-of-length"
                                    : "tile-more-than-three-of-length"
                                }`}
                              >
                                {" "}
                                {items}
                              </div>
                              <br />
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="_Vowel " style={{ marginLeft: "-80px" }}>
                      {WhiteBoardJson[0].Vowel._vowel.map((items, index) => {
                        // console.log(items.length )
                        return (
                          <>
                            <div
                              id={
                                "Galaxy_" +
                                items +
                                "_" +
                                WhiteBoardJson[0].Vowel.frontboard +
                                "_vowels"
                              }
                              // style={{
                              //   marginLeft: `${index * 40}px`,
                              // }}
                              // style={{ backgroundColor: "blue" }}
                              className={`drag-drop-galaxy drag-drop-galaxy_prefix drag-drop-galaxy-one noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}
                            </div>
                            <br />
                          </>
                        );
                      })}
                    </div>
                    <div style={{ marginTop: "60px" }}>
                      <div className="" style={{ marginLeft: "-60px" }}>
                        {/* -34px */}
                        {WhiteBoardJson[0].row_2.data.map((items, index) => {
                          // console.log(items.length )
                          return (
                            <>
                              <div
                                id={
                                  "Galaxy_" +
                                  items +
                                  "_" +
                                  WhiteBoardJson[0].row_2.frontboard +
                                  "_diphthongs"
                                }
                                style={{ textAlign: "center" }}
                                className={`drag-drop-galaxy drag-drop-galaxy_prefix drag-drop-galaxy-one noselect text-center ${items}_frontofboard_diphthongs ${
                                  items.length < 3
                                    ? "tile-less-than-three-of-length"
                                    : "tile-more-than-three-of-length"
                                }`}
                              >
                                {" "}
                                {items}
                              </div>
                              {/* <br /> */}
                            </>
                          );
                        })}
                        {WhiteBoardJson[0].row_2.yellow.map((items, index) => {
                          // console.log(items.length )
                          return (
                            <>
                              <div
                                id={
                                  "Galaxy_" +
                                  items +
                                  "_" +
                                  WhiteBoardJson[0].row_2.frontboard +
                                  "_diphthongs"
                                }
                                style={{ textAlign: "center" }}
                                className={`drag-drop-galaxy drag-drop-galaxy_Yellows drag-drop-galaxy-one noselect text-center ${items}_frontofboard_diphthongs ${
                                  items.length < 3
                                    ? "tile-less-than-three-of-length"
                                    : "tile-more-than-three-of-length"
                                }`}
                              >
                                {" "}
                                {items}
                              </div>
                              {/* <br /> */}
                            </>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          textAlign: "right",
                          marginRight: "0px", //20px
                        }}
                      >
                        {WhiteBoardJson[0].row_3.data.map((items, index) => {
                          // console.log(items.length )
                          return (
                            <>
                              <div
                                id={
                                  "Galaxy_" +
                                  items +
                                  "_" +
                                  WhiteBoardJson[0].row_3.frontboard +
                                  "_diphthongs"
                                }
                                style={{ textAlign: "center" }}
                                className={`drag-drop-galaxy drag-drop-galaxy_prefix drag-drop-galaxy-one noselect text-center ${items}_frontofboard_diphthongs ${
                                  items.length < 3
                                    ? "tile-less-than-three-of-length"
                                    : "tile-more-than-three-of-length"
                                }`}
                              >
                                {" "}
                                {items}
                              </div>
                              {/* <br /> */}
                            </>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {WhiteBoardJson[0].row_4.data.map((items, index) => {
                          // console.log(items.length )
                          return (
                            <>
                              <div
                                id={
                                  "Galaxy_" +
                                  items +
                                  "_" +
                                  WhiteBoardJson[0].row_4.frontboard +
                                  "_diphthongs"
                                }
                                style={{ textAlign: "center" }}
                                className={`drag-drop-galaxy drag-drop-galaxy_prefix drag-drop-galaxy-one noselect text-center ${items}_frontofboard_diphthongs ${
                                  items.length < 3
                                    ? "tile-less-than-three-of-length"
                                    : "tile-more-than-three-of-length"
                                }`}
                              >
                                {" "}
                                {items}
                              </div>
                              {/* <br /> */}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12" style={{ marginTop: "20px" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ marginTop: "25px" }}>
                      {WhiteBoardJson[0].row_5.yellow.map((items) => {
                        // console.log(items.length )
                        return (
                          <>
                            <div
                              id={
                                "Galaxy_" +
                                items +
                                "_" +
                                WhiteBoardJson[0].row_5.frontboard +
                                "_yellows"
                              }
                              // style={{ marginLeft: `${index * 2}px` }}
                              style={{ textAlign: "center" }}
                              className={`drag-drop-galaxy drag-drop-galaxy_Yellows drag-drop-galaxy-one noselect text-center ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                            <br />
                          </>
                        );
                      })}
                      {WhiteBoardJson[0].row_6.yellow.map((items) => {
                        // console.log(items.length )
                        return (
                          <>
                            <div
                              id={
                                "Galaxy_" +
                                items +
                                "_" +
                                WhiteBoardJson[0].row_6.frontboard +
                                "_yellows"
                              }
                              // style={{ marginLeft: `${index * 2}px` }}
                              style={{ textAlign: "center" }}
                              className={`drag-drop-galaxy drag-drop-galaxy_Yellows drag-drop-galaxy-one noselect text-center ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                            <br />
                          </>
                        );
                      })}
                      {WhiteBoardJson[0].row_7.yellow.map((items) => {
                        // console.log(items.length )
                        return (
                          <>
                            <div
                              id={
                                "Galaxy_" +
                                items +
                                "_" +
                                WhiteBoardJson[0].row_7.frontboard +
                                "_yellows"
                              }
                              // style={{ marginLeft: `${index * 2}px` }}
                              style={{
                                textAlign: "center",
                                marginLeft: "80px",
                              }}
                              className={`drag-drop-galaxy drag-drop-galaxy_Yellows drag-drop-galaxy-one noselect text-center ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                            <br />
                          </>
                        );
                      })}
                    </div>
                    <div style={{ marginLeft: "-40px" }} className="">
                      <div>
                        {WhiteBoardJson[0].row_5.data.map((items) => {
                          // console.log(items.length )
                          return (
                            <>
                              <div
                                id={
                                  "Galaxy_" +
                                  items +
                                  "_" +
                                  WhiteBoardJson[0].row_5.frontboard +
                                  "_walkers"
                                }
                                // style={{ marginLeft: `${index * 2}px` }}
                                style={{
                                  textAlign: "center",
                                }}
                                className={`drag-drop-galaxy drag-drop-galaxy_prefix drag-drop-galaxy-one noselect text-center ${
                                  items.length < 3
                                    ? "tile-less-than-three-of-length"
                                    : "tile-more-than-three-of-length"
                                }`}
                              >
                                {" "}
                                {items}{" "}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div style={{ marginTop: "63px", marginRight: "-118px" }}>
                        {WhiteBoardJson[0].Reds._reds.map((items) => {
                          // console.log(items.length )
                          return (
                            <>
                              <div
                                id={
                                  "Galaxy_" +
                                  items +
                                  "_" +
                                  WhiteBoardJson[0].Reds.frontboard +
                                  "_reds"
                                }
                                // style={{ marginLeft: `${index * 2}px` }}
                                style={{
                                  textAlign: "center",
                                }}
                                className={`drag-drop-galaxy drag-drop-galaxy_Reds drag-drop-galaxy-one noselect ${
                                  items.length < 3
                                    ? "tile-less-than-three-of-length"
                                    : "tile-more-than-three-of-length"
                                }`}
                              >
                                {" "}
                                {items}{" "}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </>
  );
};

export default GalaxyBoard;
