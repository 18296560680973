exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Whiteboard_Whiteboard__JrLvO {\r\n    \r\n}\r\n.Whiteboard_setdiveheight__1y9sh {\r\n    height: \"70%\";\r\n    aspect-ratio: 1;\r\n    margin-left:10;\r\n    margin-top:10;\r\n    margin-bottom:10;\r\n  }\r\n\r\n", ""]);

// exports
exports.locals = {
	"Whiteboard": "Whiteboard_Whiteboard__JrLvO",
	"setdiveheight": "Whiteboard_setdiveheight__1y9sh"
};