import React, { FC, useRef, useMemo, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import "dotenv/config";
import styles from "./Whiteboard.module.css";
import { useNavigate } from "react-router-dom";
import "../Tiles/Tiles";
import Interactable from "../../contexts/Interactable";
import { v4 as uuid } from "uuid";
import interact from "interactjs";
import styled from "styled-components";
import { isGeneratorFunction } from "util/types";
import "bootstrap/dist/css/bootstrap.css";
import "../AdminDashBoard/AdminDashBoard.css";
import { useScreenshot } from "use-screenshot-hook";
import CloseIcon from "../pages/close";
import { Helmet } from "react-helmet";

import LoginPage from "../pages/LoginPage";
import $ from "jquery";
import { Console } from "console";
// import gapi from "@type/gapi";
import { gapi } from "gapi-script";
import { start } from "repl";
import WhiteBoardJson from "../../whiteboard.json";
import BackBoardJson from "../../BackSideBoard.json";
import { Link } from "react-router-dom";

import "../../contexts/position-style.css";
import ReactModal from "react-modal-resizable-draggable";
import WritingExData from "../WritingExData";
import { BsFillVolumeDownFill } from "react-icons/bs";
// import Dropdown from 'react-dropdown-modal';
// import 'react-dropdown-modal/lib/dropdown.css';
import Dropdown from "react-bootstrap/Dropdown";
import { Container, Form, FormCheck, Button } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Adj from "../Adj";
var frontdropzoneflag = false;
var backdropzoneflag = false;
const options = [
  { value: "Writing", label: "Writing" },
  { value: "Typing", label: "Typing" },
  { value: "", label: "" },
];
const offset = {
  left: 150,
  top: 50,
};

// const my-modal-custom-class = { X: 160, Y: 14 };
// // const secondPosition = { X: 160, Y: 240 };

declare global {
  interface window {
    n;
    React: any;
  }
}
// const position = { x: 0, y: 0 };
let LastDraggedX = 0;
const clientId =
  "478729096121-acrvqi9nptdhg2j8jsbnnoomjjnr5g7q.apps.googleusercontent.com";
const API_Key = "AIzaSyCeMVQIgdS6PqmmW8sKwK1IWBzOmmbUVOc";
const Client_secret = "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";
const scope = "https://www.googleapis.com/auth/drive";
function noOverlap1(event) {
  var dx = event.dx;
  var dy = event.dy;
  var overlapElements = document.querySelectorAll(".drag-drop");

  //just for flagging when the target would overlap another element
  var overlap = false;
  for (let i = 0; i < overlapElements.length; i++) {
    var targetDims = event.target.getBoundingClientRect();
    var overlapElementDims = overlapElements[i].getBoundingClientRect();
    overlapElements[i].classList.remove("highlightTiles");
    //make sure the element doesn't look at itself..
    if (overlapElements[i] != event.target) {
      //checks if the target "doesn't" overlap
      if (
        targetDims.right + dx < overlapElementDims.left + 3 ||
        targetDims.left + 1 + dx > overlapElementDims.right - 3 ||
        targetDims.top + 1 + dy > overlapElementDims.bottom ||
        targetDims.bottom + dy < overlapElementDims.top + 1
      ) {
        //Basically, the target element doesn't overlap the current
        //element in the HTMLCollection, do nothing and go to the
        //next iterate
      } else {
        //This is if the target element would overlap the current element

        //set overlap to true and break out of the for loop to conserve time.

        overlap = true;
        var rectangles_L = overlapElementDims.left;
        var rectangels_T = overlapElementDims.top;
        var lastDragged_L = targetDims.left;
        var lastDragged_T = targetDims.top;
        var xDistance = rectangles_L - lastDragged_L;
        var yDistance = rectangels_T - lastDragged_T;
        var roundUp_X = Math.ceil(xDistance);
        var roundUp_Y = Math.ceil(yDistance);

        var move_X = '"' + "+=" + roundUp_X + "px" + '"';
        var move_Y = "+=" + roundUp_Y + "px";
        console.log(overlapElementDims.width);

        console.log("move_X = " + roundUp_X);
        console.log("move_Y = " + roundUp_Y);
        var parseXoverValue = 0;
        var parseXMoveValue = 0;
        if (roundUp_X < 0) {
          parseXoverValue =
            parseInt(event.target.dataset.x) +
            overlapElementDims.width +
            roundUp_X;
          parseXMoveValue = overlapElementDims.width + roundUp_X;
        } else {
          parseXoverValue =
            parseFloat(event.target.dataset.x) +
            overlapElementDims.width +
            roundUp_X;
          parseXMoveValue = overlapElementDims.width + roundUp_X;
        }

        event.target.style.webkitTransform = event.target.style.transform =
          "translate(" +
          parseXoverValue +
          "px, " +
          event.target.dataset.y +
          "px)";
        event.rect.left = parseInt(event.rect.left) + parseXMoveValue;
        event.rect.right = event.rect.right + parseXMoveValue;
        event.rect["data_x"] = parseXoverValue;
        event.rect["data_y"] = event.target.dataset.y;
        event.target.dataset.x = parseXoverValue;
        //event.rect.left=event.rect.left+overlapElementDims.width+10+'px';
        // break;
        //overlapElements=document.querySelectorAll('.drag-drop');
        //i=0;
      }
    }
  }

  // if (overlap === true) {

  //   console.log("hello overlap after drag end")
  //   //then reset x and y
  //   event.target.x = 0;
  //   event.target.y = 0;
  //   const parse_array = event.target.id.split('_');
  //   // let urld=parse_array[0];
  //   if (parse_array[2]) {
  //     let p1 = event.target.removeAttribute("style");
  //     let p2 = event.target.removeAttribute("data-x");
  //     let p3 = event.target.removeAttribute("data-y");
  //     // let p4=event.target.removeAttribute("left");
  //     // let p5=event.target.removeAttribute("right");
  //     // let p6=event.target.removeAttribute("top");
  //     event.target.style.position = "absolute";
  //     // element.style.position = "relative";
  //     event.target.style.left = event.x;
  //     event.target.style.top = event.y;
  //     // event.target.style.fontSize='15px';
  //     // event.target.style.width = '4.125rem'
  //   }
  //   else {
  //     let p1 = event.target.removeAttribute("style");
  //     let p2 = event.target.removeAttribute("data-x");
  //     let p3 = event.target.removeAttribute("data-y");
  //     let p4 = event.target.removeAttribute("left");
  //     let p5 = event.target.removeAttribute("right");
  //     let p6 = event.target.removeAttribute("top");
  //   }

  // }
}

function CheckOverlap(event, overlapElements) {
  var dx = event.dx;
  var dy = event.dy;

  //just for flagging when the target would overlap another element
  var overlap = false;
  var targetDims = event.target.getBoundingClientRect();
  var j = 0;
  for (let i = 0; i < overlapElements.length; i++) {
    var overlapElementDims = overlapElements[i].getBoundingClientRect();
    j = i;
    //make sure the element doesn't look at itself..
    if (overlapElements[i] != event.target) {
      //checks if the target "doesn't" overlap
      if (
        targetDims.right + dx < overlapElementDims.left + 2 ||
        targetDims.left + 1 + dx > overlapElementDims.right - 2 ||
        targetDims.top + 1 + dy > overlapElementDims.bottom ||
        targetDims.bottom + dy < overlapElementDims.top + 1
      ) {
        //Basically, the target element doesn't overlap the current
        //element in the HTMLCollection, do nothing and go to the
        //next iterate
        overlapElements[i].classList.remove("highlightTiles");
      } else {
        //This is if the target element would overlap the current element

        //set overlap to true and break out of the for loop to conserve time.

        overlap = true;
        overlapElements[i].classList.add("highlightTiles");
      }
    }
  }

  // if(overlap === true){
  //   console.log("hello overlaping  on  drag event"+ event.currentTarget.id +" overlap "+overlapElements[j]);
  // }
}
//set drop zone front board
// dropzoneFrontboard

interact(".dropzoneFrontboard").dropzone({
  overlap: 0.75,
  ondropactivate: function(event) {
    event.target.classList.add("drop-active");
  },
  ondragenter: function(event) {
    var draggableElement = event.relatedTarget;
    console.log(event.relatedTarget.id);
    let id = event.relatedTarget.id;
    const parse_array = id.split("_");
    console.log(parse_array.length);
    let path_dir = parse_array[1];
    if (
      path_dir == "backofboard" &&
      parse_array[3] &&
      parse_array[parse_array.length - 1] != "draginfrontboard"
    ) {
      var element = event.relatedTarget.cloneNode(true);
      let id = event.relatedTarget.id;
      frontdropzoneflag = true;
      // const parse_array = id.split('_');
      // let path_dir = parse_array[1];
      if (parse_array[3]) {
        element.id = element.id = element.id + "_" + "draginfrontboard";
        element.style.position = "absolute";

        var targetDims = event.relatedTarget.getBoundingClientRect();
        var getwidthandheight = document.getElementById("frontwhiteboard");
        console.log(event.relatedTarget);

        //element.style.position = "absolute";
        element.style.transform =
          "translate(" +
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-x")
          ) +
          "px, " +
          (parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-y")
          ) +
            600) +
          "px)";
        element.setAttribute(
          "left",
          parseFloat(
            document.getElementById(event.relatedTarget.id).getAttribute("left")
          )
        );
        element.setAttribute(
          "right",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("right")
          )
        );
        element.setAttribute(
          "top",
          parseFloat(
            document.getElementById(event.relatedTarget.id).getAttribute("top")
          )
        );
        element.setAttribute(
          "bottom",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("bottom")
          )
        );
        element.setAttribute(
          "data-x",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-x")
          )
        );
        element.setAttribute(
          "data-y",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-y")
          ) + 600
        );
        //container = document.getElementById("frontwhiteboard");
        // element.clientX = event.clientX + 25;
        // element.clientY = event.clientY + 25;
        // console.log(event.clientY)
        // let sleft = parseFloat(event.relatedTarget.style.left);
        // let stop = parseFloat(event.relatedTarget.style.top);
        // console.log(stop);
        // element.style.left = event.pageX + 'px';
        // element.style.top = event.pageY-100 + 'px';
      } else {
        element.id = element.id + "_" + "draginfrontboard";
        element.style.position = "absolute";
        element.clientX = event.clientX + 25;
        element.clientY = event.clientY - 25;
        element.style.left = event.PageX + "px"; //(20+event.clientX+10);
        element.style.top = event.pageY - 100 + "px";
      }

      //let p=event.relatedTarget.deattach()
      var container = document.getElementById("frontwhiteboard");
      if (container) {
        container && container.appendChild(element);
      }
      var t = document.getElementById(event.relatedTarget.id);
      t.remove();
      localStorage.removeItem(event.relatedTarget.id);
    }
    var dropzoneElement = event.target;
  },
  ondragleave: function(event) {
    // remove the drop feedback style
    //event.target.classList.remove('drop-target')
    // event.relatedTarget.classList.remove('can-drop')
    var draggableElement = event.relatedTarget;
    console.log(event.relatedTarget.id);
    let id = event.relatedTarget.id;
    const parse_array = id.split("_");
    console.log(parse_array.length);
    let path_dir = parse_array[1];
    // if (parse_array[3]) {
    if (
      path_dir == "backofboard" &&
      parse_array[3] &&
      parse_array[parse_array.length - 1] == "draginfrontboard"
    ) {
      var element = event.relatedTarget.cloneNode(true);
      //  let id = event.relatedTarget.id;
      const parse_array = id.split("_");
      var makeid = "";
      for (var i = 0; i < parse_array.length - 1; i++) {
        if (i == 0) {
          makeid = parse_array[i];
        } else {
          makeid = makeid + "_" + parse_array[i];
        }
      }
      // let path_dir = parse_array[1];
      if (parse_array[3]) {
        element.id = makeid;
        element.style.position = "absolute";
        element.style.transform =
          "translate(" +
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-x")
          ) +
          "px, " +
          (parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-y")
          ) -
            600) +
          "px)";
        element.setAttribute(
          "left",
          parseFloat(
            document.getElementById(event.relatedTarget.id).getAttribute("left")
          )
        );
        element.setAttribute(
          "right",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("right")
          )
        );
        element.setAttribute(
          "top",
          parseFloat(
            document.getElementById(event.relatedTarget.id).getAttribute("top")
          )
        );
        element.setAttribute(
          "bottom",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("bottom")
          )
        );
        element.setAttribute(
          "data-x",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-x")
          )
        );
        element.setAttribute(
          "data-y",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-y")
          ) - 600
        );
        // element.style.position = "absolute";
        // element.clientX = event.clientX + 25;
        // element.clientY = event.clientY + 25;
        // let sleft = parseFloat(event.relatedTarget.style.left);
        // let stop = parseFloat(event.relatedTarget.style.top);
        // console.log(event.relatedTarget)
        // element.style.left = event.pageX+ 'px';
        // // element.style.left = sleft + parseFloat('100') + 'px'; //(20+event.clientX+10);
        // // element.style.top = stop + parseFloat('100') + 'px'; //(20+event.clientY+10);
        // element.style.top = event.pageY +100 +'px';
      } else {
        element.id = makeid;
        element.style.position = "absolute";
        element.clientX = event.clientX + 25;
        element.clientY = event.clientY + 25;
        element.style.left = event.pageX + "px"; //(20+event.clientX+10);
        element.style.top = event.pageY + 100 + "px";
      }
      var container = document.getElementById("backwhiteboard");
      if (container) {
        container && container.appendChild(element);
      }
      var t = document.getElementById(event.relatedTarget.id);
      t.remove();
      // event.relatedTarget.remove();
      localStorage.removeItem(event.relatedTarget.id);
    }
  },
  ondrop: function(event) {},
  ondropdeactivate: function(event) {
    // remove active dropzone feedback
    event.target.classList.remove("drop-active");
    event.target.classList.remove("drop-target");
  },
});
//set drop zone backboard
interact(".dropzoneBackboard").dropzone({
  overlap: 0.75,
  ondropactivate: function(event) {
    event.target.classList.add("drop-active");
  },
  ondragenter: function(event) {
    var draggableElement = event.relatedTarget;
    console.log(event.relatedTarget.id);
    let id = event.relatedTarget.id;
    const parse_array = id.split("_");
    console.log(parse_array.length);
    let path_dir = parse_array[1];

    // if (parse_array[3]) {
    if (
      path_dir == "frontofboard" &&
      parse_array[3] &&
      parse_array[parse_array.length - 1] != "draginbackboard"
    ) {
      var element = event.relatedTarget.cloneNode(true);
      let id = event.relatedTarget.id;
      backdropzoneflag = true;
      // const parse_array = id.split('_');
      // let path_dir = parse_array[1];
      if (parse_array[3]) {
        element.id = element.id = element.id + "_" + "draginbackboard";
        0;

        element.style.position = "absolute";
        // element.clientX = event.clientX + 25;
        // element.clientY = event.clientY + 25;
        // element.style.left = event.pageX + 'px';
        // element.style.top = event.pageY+100 + 'px'; //(20+event.clientY+10);
        element.style.transform =
          "translate(" +
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-x")
          ) +
          "px, " +
          (parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-y")
          ) -
            600) +
          "px)";
        element.setAttribute(
          "left",
          parseFloat(
            document.getElementById(event.relatedTarget.id).getAttribute("left")
          )
        );
        element.setAttribute(
          "right",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("right")
          )
        );
        element.setAttribute(
          "top",
          parseFloat(
            document.getElementById(event.relatedTarget.id).getAttribute("top")
          )
        );
        element.setAttribute(
          "bottom",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("bottom")
          )
        );
        element.setAttribute(
          "data-x",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-x")
          )
        );
        element.setAttribute(
          "data-y",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-y")
          ) - 600
        );

        //let p=event.relatedTarget.deattach()
        var container = document.getElementById("backwhiteboard");
        if (container) {
          container && container.appendChild(element);
        }
        var t = document.getElementById(event.relatedTarget.id);
        t.remove();
        event.relatedTarget.remove();
        localStorage.removeItem(event.relatedTarget.id);
      }
    }
    var dropzoneElement = event.target;
  },
  ondragleave: function(event) {
    // remove the drop feedback style
    //event.target.classList.remove('drop-target')
    // event.relatedTarget.classList.remove('can-drop')
    var draggableElement = event.relatedTarget;
    console.log(event.relatedTarget.id);
    let id = event.relatedTarget.id;
    const parse_array = id.split("_");
    console.log(parse_array.length);
    let path_dir = parse_array[1];
    // if (parse_array[3]) {
    if (
      path_dir == "frontofboard" &&
      parse_array[3] &&
      parse_array[parse_array.length - 1] == "draginbackboard"
    ) {
      var element = event.relatedTarget.cloneNode(true);
      //  let id = event.relatedTarget.id;
      const parse_array = id.split("_");
      var makeid = "";
      for (var i = 0; i < parse_array.length - 1; i++) {
        if (i == 0) {
          makeid = parse_array[i];
        } else {
          makeid = makeid + "_" + parse_array[i];
        }
      }
      // let path_dir = parse_array[1];
      if (parse_array[3]) {
        element.id = makeid;
        element.style.position = "absolute";
        element.style.transform =
          "translate(" +
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-x")
          ) +
          "px, " +
          (parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-y")
          ) +
            600) +
          "px)";
        element.setAttribute(
          "left",
          parseFloat(
            document.getElementById(event.relatedTarget.id).getAttribute("left")
          )
        );
        element.setAttribute(
          "right",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("right")
          )
        );
        element.setAttribute(
          "top",
          parseFloat(
            document.getElementById(event.relatedTarget.id).getAttribute("top")
          )
        );
        element.setAttribute(
          "bottom",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("bottom")
          )
        );
        element.setAttribute(
          "data-x",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-x")
          )
        );
        element.setAttribute(
          "data-y",
          parseFloat(
            document
              .getElementById(event.relatedTarget.id)
              .getAttribute("data-y")
          ) + 600
        );
        // element.clientX = event.clientX + 25;
        // element.clientY = event.clientY + 25;
        // let sleft = parseFloat(event.relatedTarget.style.left);
        // let stop = parseFloat(event.relatedTarget.style.top);
        // element.style.left = event.pageX+ 'px';
        // // element.style.left = sleft + parseFloat('100') + 'px'; //(20+event.clientX+10);
        // // element.style.top = stop + parseFloat('100') + 'px'; //(20+event.clientY+10);
        // element.style.top = event.pageY +'px';
      } else {
        element.id = makeid;
        element.style.position = "absolute";
        element.clientX = event.clientX + 25;
        element.clientY = event.clientY + 25;
        element.style.left = event.pageX + "px"; //(20+event.clientX+10);
        element.style.top = event.pageY + "px";
      }

      var container = document.getElementById("frontwhiteboard");
      if (container) {
        container && container.appendChild(element);
      }
      var t = document.getElementById(event.relatedTarget.id);
      t.remove();
      // event.relatedTarget.remove();
      localStorage.removeItem(event.relatedTarget.id);
    }
  },
  ondrop: function(event) {},
  ondropdeactivate: function(event) {
    // remove active dropzone feedback
    event.target.classList.remove("drop-active");
    event.target.classList.remove("drop-target");
  },
});

var soundWhooshWB = true;
var soundTilesWB = true;
interact(".drag-drop")
  .draggable({
    modifiers: [
      interact.modifiers.snap({
        targets: [interact.snappers.grid({ x: 2, y: 1 })],
        range: Infinity,
        relativePoints: [{ x: 0, y: 0 }],
      }),
      interact.modifiers.restrict({
        //restriction: element.parentNode,
        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
        endOnly: false,
      }),
    ],
    inertia: true,
  })
  .on("dragmove", function(event) {
    const target = event.target;
    const dx = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    const dy = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
    target.style.webkitTransform = target.style.transform =
      "translate(" + dx + "px, " + dy + "px)";
    // target.style.zIndex='9999';
    // update the posiion attributes
    target.setAttribute("data-x", dx);
    target.setAttribute("data-y", dy);
    if (event.client.x > LastDraggedX) LastDraggedX = event.client.x;
    var element = document.querySelectorAll(".drag-drop");
    CheckOverlap(event, element);
  })
  .on("dragend", function(event) {
    event.target.setAttribute("left", event.rect.left);
    event.target.setAttribute("right", event.rect.right);
    event.target.setAttribute("top", event.rect.top);
    event.target.setAttribute("bottom", event.rect.bottom);
    console.log(event.currentTarget.id);
    console.log(event.rect);
    console.log(event.target.dataset.x);
    console.log(event.target.dataset.y);
    let id = event.currentTarget.id;
    const parse_array = id.split("_");
    let path_dir = parse_array[1];
    // if (parse_array[3]) {
    if (path_dir == "frontofboard") {
      if (
        LastDraggedX >
        document.getElementById("frontwhiteboard").offsetWidth +
          document.getElementById("frontwhiteboard").offsetLeft
      ) {
        //  console.log("hello");
        const parse_array = event.currentTarget.id.split("_");
        let urld = parse_array[0];
        let path_char = parse_array[1];
        if (parse_array[3]) {
          console.log(parse_array[3]);
          event.target.remove();
        } else {
          // localStorage.setItem("hide_"+event.currentTarget.id, JSON.stringify(true));
          // document.getElementById(event.currentTarget.id).style.visibility = 'hidden';
          //localStorage.removeItem(event.currentTarget.id);
          event.target.removeAttribute("style");
          event.target.removeAttribute("data-x");
          event.target.removeAttribute("data-y");
          event.target.removeAttribute("left");
          event.target.removeAttribute("right");
          event.target.removeAttribute("top");
        }
        let audiofileurl = process.env.PUBLIC_URL + "removesound.mp3";
        let payeaudio = new Audio(audiofileurl);
        if(soundWhooshWB){
          payeaudio.play();
      }
        LastDraggedX = 0;
      } else {
        //noOverlap1(event);
        clonediv(event);
      }
    } else if (path_dir == "backofboard") {
      if (
        LastDraggedX >
        document.getElementById("backwhiteboard").offsetWidth +
          document.getElementById("backwhiteboard").offsetLeft
      ) {
        //  console.log("hello");
        const parse_array = event.currentTarget.id.split("_");
        let urld = parse_array[0];
        let path_char = parse_array[1];
        if (parse_array[3]) {
          console.log(parse_array[3]);
          event.target.remove();
        } else {
          localStorage.removeItem(event.currentTarget.id);
          event.target.removeAttribute("style");
          event.target.removeAttribute("data-x");
          event.target.removeAttribute("data-y");
          event.target.removeAttribute("left");
          event.target.removeAttribute("right");
          event.target.removeAttribute("top");
        }
        let audiofileurl = process.env.PUBLIC_URL + "removesound.mp3";
        let payeaudio = new Audio(audiofileurl);
        if(soundWhooshWB){
          payeaudio.play();
      }
        LastDraggedX = 0;
      } else {
        //noOverlap1(event);//For magnetic Tiles
        clonediv(event);
      }
    }
  })
  .on("doubletap", function(event) {
    clickchar(event.currentTarget.id);
  })
  .on("hold", function(event) {
    const { currentTarget, interaction } = event;
    let element = currentTarget;
    element = currentTarget.cloneNode(true);
    let id = currentTarget.id;
    const parse_array = id.split("_");
    let path_dir = parse_array[1];
    element.id = element.id + "_" + uuid();
    if (parse_array[3]) {
      element.style.position = "absolute";
      element.clientX = event.clientX + 25;
      element.clientY = event.pageY + 25;
      let sleft = parseFloat(currentTarget.style.left);
      let stop = parseFloat(currentTarget.style.top);

      // element.style.left = sleft + parseFloat('50') + 'px'; //(20+event.clientX+10);
      // element.style.top = stop + parseFloat('50') + 'px'; //(20+event.clientY+10);
      // element.style.left = event.pageX + 25 + 'px'; //(20+event.clientX+10);
      // element.style.top =event.pageY +25;
    } else {
      // if (event.target.style.transform) {
      //   // const dx = (parseFloat(event.target.getAttribute("data-x")) || 0) + event.dx;
      //   // const dy = (parseFloat(event.target.getAttribute("data-y")) || 0) + event.dy;
      //   element.style.transform = "";
      //   element.setAttribute("data-x", 0);
      //   element.setAttribute("data-y", 0);
      // }
      // element.style.position = "absolute";
      // // element.clientX = event.clientX + 25;
      // // element.clientY = event.pageY - 25;
      // element.style.left = event.clientX + 25 + 'px'; //(20+event.clientX+10);
      // element.style.top = event.pageY + 25 + 'px'; //(20+event.clientY+10);
    }
    console.log(event);
    var container;
    if (path_dir == "frontofboard") {
      var targetDims = event.target.getBoundingClientRect();
      var getwidthandheight = document.getElementById("frontwhiteboard");
      element.setAttribute("left", targetDims.left);
      element.setAttribute("right", targetDims.right);
      element.setAttribute("top", targetDims.top);
      element.setAttribute("bottom", targetDims.bottom);
      element.setAttribute("data-x", targetDims.x - 585);
      element.setAttribute("data-y", targetDims.y - 535);
      element.style.position = "absolute";
      element.style.transform =
        "translate(" +
        (targetDims.x - 585) +
        "px, " +
        (targetDims.y - 535) +
        "px)";
      container = document.getElementById("frontwhiteboard");
    } else if (path_dir == "backofboard") {
      var targetDims = event.target.getBoundingClientRect();
      var getwidthandheight = document.getElementById("backwhiteboard");
      element.setAttribute("left", targetDims.left);
      element.setAttribute("right", targetDims.right);
      element.setAttribute("top", targetDims.top);
      element.setAttribute("bottom", targetDims.bottom);
      element.setAttribute("data-x", targetDims.x - 565);
      element.setAttribute("data-y", targetDims.y - 550);
      element.style.position = "absolute";
      element.style.transform =
        "translate(" +
        (targetDims.x - 565) +
        "px, " +
        (targetDims.y - 550) +
        "px)";
      //if(document.getElementById("hidefrontbutton").getAttribute('disabled')=='true')
      // {
      //   //  element.style.top = element.style.top + 620 + 'px';
      //    element.style.top =event.pageY +25 + 'px';
      // }
      // else{

      //   element.style.top =event.pageY +25 + 'px';
      // }

      container = document.getElementById("backwhiteboard");

     // container = document.getElementById("backwhiteboard");
    }

    if (container) {
      container && container.appendChild(element);
    }
  });

let clickchar = (id) => {
  console.log(id);
  // let urld=id.replace("_drop","");
  const parse_array = id.split("_");
  let urld = parse_array[0];
  let path_dir = parse_array[1];
  let path_char = parse_array[2];
  const url_ld = urld.split(" ");
  console.log(urld);
  console.log(path_char);
  if (url_ld[0] == "wh") url_ld[0] = "wh-";
  if (url_ld[0] == "con") url_ld[0] = urld + "-";
  if (url_ld[0] == "true") url_ld[0] = "ture";
  // console.log('url', urld);
  // if (url_ld[0] == "le") url_ld[0] = "tle";
  // console.log('url', urld);

  let audiofileurl =
    process.env.PUBLIC_URL +
    "/audio/" +
    path_dir +
    "/" +
    path_char +
    "/" +
    url_ld[0] +
    ".mp3";
  let payeaudio = new Audio(audiofileurl);
        if(soundTilesWB){
            payeaudio.play();
        }
};
function resetdivonLogout() {
  // console.log("Reset");
  // var element = document.querySelectorAll('.drag-drop');

  // for (let i = 0; i < element.length; i++) {

  //     localStorage.removeItem(element[i].id);
  //     element[i].removeAttribute("style");
  //     element[i].removeAttribute("data-x");
  //     element[i].removeAttribute("data-y");
  //     element[i].removeAttribute("left");
  //     element[i].removeAttribute("right");
  //     element[i].removeAttribute("top");
  //     const parse_array = element[i].id.split('_');
  //     let urld = parse_array[0];
  //     if (parse_array[3]) {
  //       console.log(element[i].id);
  //       var t = document.getElementById(element[i].id);
  //       t.remove();
  //     }
  // }
  localStorage.clear();
}
function resetdiv() {
  console.log("Reset");
  var element = document.querySelectorAll(".drag-drop");
  let deletefrontboard =
    document.getElementById("hidefrontbutton").getAttribute("disabled") ==
    "true";
  let deletebackboard =
    document.getElementById("hidebackbutton").getAttribute("disabled") ==
    "true";
  if (deletefrontboard) {
    for (let i = 0; i < element.length; i++) {
      const parse_array = element[i].id.split("_");
      let urld = parse_array[0];
      if (
        parse_array[1] == "frontofboard" &&
        parse_array[parse_array.length - 1] != "draginbackboard"
      ) {
        localStorage.removeItem(element[i].id);
        element[i].removeAttribute("style");
        element[i].removeAttribute("data-x");
        element[i].removeAttribute("data-y");
        element[i].removeAttribute("left");
        element[i].removeAttribute("right");
        element[i].removeAttribute("top");
        if (parse_array[3]) {
          console.log(element[i].id);
          var t = document.getElementById(element[i].id);
          t.remove();
        }
      } else if (
        parse_array[1] == "backofboard" &&
        parse_array[parse_array.length - 1] === "draginfrontboard"
      ) {
        localStorage.removeItem(element[i].id);
        element[i].removeAttribute("style");
        element[i].removeAttribute("data-x");
        element[i].removeAttribute("data-y");
        element[i].removeAttribute("left");
        element[i].removeAttribute("right");
        element[i].removeAttribute("top");

        if (parse_array[3]) {
          console.log(element[i].id);
          var t = document.getElementById(element[i].id);
          t.remove();
        }
      }
    }
  } else if (deletebackboard) {
    for (let i = 0; i < element.length; i++) {
      const parse_array = element[i].id.split("_");
      let urld = parse_array[0];
      if (
        parse_array[1] == "backofboard" &&
        parse_array[parse_array.length - 1] != "draginfrontboard"
      ) {
        localStorage.removeItem(element[i].id);
        element[i].removeAttribute("style");
        element[i].removeAttribute("data-x");
        element[i].removeAttribute("data-y");
        element[i].removeAttribute("left");
        element[i].removeAttribute("right");
        element[i].removeAttribute("top");

        if (parse_array[3]) {
          console.log(element[i].id);
          var t = document.getElementById(element[i].id);
          t.remove();
        }
      } else if (
        parse_array[1] == "frontofboard" &&
        parse_array[parse_array.length - 1] === "draginbackboard"
      ) {
        localStorage.removeItem(element[i].id);
        element[i].removeAttribute("style");
        element[i].removeAttribute("data-x");
        element[i].removeAttribute("data-y");
        element[i].removeAttribute("left");
        element[i].removeAttribute("right");
        element[i].removeAttribute("top");

        if (parse_array[3]) {
          console.log(element[i].id);
          var t = document.getElementById(element[i].id);
          t.remove();
        }
      }
    }
  } else {
    for (let i = 0; i < element.length; i++) {
      console.log(element[i]);
      localStorage.removeItem(element[i].id);
      element[i].removeAttribute("style");
      element[i].removeAttribute("data-x");
      element[i].removeAttribute("data-y");
      element[i].removeAttribute("left");
      element[i].removeAttribute("right");
      element[i].removeAttribute("top");
      const parse_array = element[i].id.split("_");
      let urld = parse_array[0];
      if (parse_array[3]) {
        console.log(element[i].id);
        var t = document.getElementById(element[i].id);
        t.remove();
      }
    }
    var values = [],
      LocalStorageitemsKeys = Object.keys(localStorage),
      i = LocalStorageitemsKeys.length;
    // for (var l = 1; l <= LocalStorageitemsKeys.length; l++) {
    while (i--) {
      //let divdata = JSON.parse(localStorage.getItem(LocalStorageitemsKeys[i]));
      var parse_array = LocalStorageitemsKeys[i].split("_");
      if (parse_array[parse_array.length - 1] == "draginbackboard") {
        localStorage.removeItem(LocalStorageitemsKeys[i]);
      } else if (parse_array[parse_array.length - 1] == "draginfrontboard") {
        localStorage.removeItem(LocalStorageitemsKeys[i]);
      }
    }
  }
}
function diffrence(a, b) {
  return a > b ? a - b : b - a;
}
function diffrenceFortop(a, b) {
  return a - b;
}
function clonediv(event) {
  console.log(event.currentTarget.id);
  let pppp = event.currentTarget.id;
  var element = document.querySelectorAll(".drag-drop");
  let j = 0;
  for (let i = 0; i < element.length; i++) {
    var t = [];
    if (element[i].id != event.currentTarget.id) {
      let left = element[i].getAttributeNode("left");
      let right = element[i].getAttributeNode("right");
      var targetDims = event.target.getBoundingClientRect();
      var magnaticElementDims = element[i].getBoundingClientRect();
      var diffeleft = diffrence(targetDims.left, magnaticElementDims.right);
      var differight = diffrence(targetDims.right, magnaticElementDims.left);
      if (
        diffeleft <= 10 &&
        diffrence(targetDims.top, magnaticElementDims.top) <= 10
      ) {
        console.log("clone left ");
        var targetDims = event.target.getBoundingClientRect();
        var magnaticElementDims = element[i].getBoundingClientRect();
        var leftsidemagnatic = diffrence(
          targetDims.left,
          magnaticElementDims.right
        );
        var topsideposition = diffrenceFortop(
          magnaticElementDims.top,
          targetDims.top
        );
        j = j + 1;
        let id = pppp;
        let top = element[i].getAttributeNode("top");
        let bottom = element[i].getAttributeNode("bottom");
        event.target.style.webkitTransform = event.target.style.transform =
          "translate(" +
          (event.target.dataset.x - leftsidemagnatic - 2) +
          "px, " +
          (event.target.dataset.y - 1 + topsideposition + 1) +
          "px)";
        // event.rect.top=targetDims.top-1+topsideposition+1;
        event.rect.top = event.rect.top - 1 + topsideposition + 1;
        if (
          document.getElementById("hidefrontbutton").getAttribute("disabled") ==
          "true"
        ) {
          //  element.style.top = element.style.top + 620 + 'px';
          event.rect.top = event.rect.top - 1 + topsideposition + 1 + "px";
        }
        event.rect.buttom = targetDims.buttom - 1 + topsideposition + 1;
        event.rect.left = event.rect.left - 2 - leftsidemagnatic;
        event.rect.right = event.rect.right - leftsidemagnatic;
        event.rect["data_x"] = event.target.dataset.x - leftsidemagnatic - 2;
        event.rect["data_y"] = event.target.dataset.y - 1 + topsideposition + 1;
        event.rect["eventName"] = event.target.innerHTML;
        event.rect["eventClass"] = event.target.className;
        localStorage.setItem(id, JSON.stringify(event.rect));
      } else if (
        differight <= 10 &&
        diffrence(targetDims.top, magnaticElementDims.top) <= 10
      ) {
        j = j + 1;
        let id = pppp;
        let top = element[i].getAttributeNode("top");
        let bottom = element[i].getAttributeNode("bottom");
        console.log("clone right ");
        console.log(differight);
        var targetDims = event.target.getBoundingClientRect();
        var magnaticElementDims = element[i].getBoundingClientRect();
        var rightsidemagnatic = diffrence(
          targetDims.right,
          magnaticElementDims.left
        );
        var topsideposition = diffrenceFortop(
          magnaticElementDims.top,
          targetDims.top
        );
        event.target.style.webkitTransform = event.target.style.transform =
          "translate(" +
          (event.target.dataset.x - 1 + rightsidemagnatic + 3) +
          "px, " +
          (event.target.dataset.y - 1 + topsideposition + 1) +
          "px)";
        //event.rect.top=targetDims.top;
        // event.rect.left = event.rect.left;
        // event.rect.right = event.rect.right;
        //event.rect.top=targetDims.top-1+topsideposition+1;
        event.rect.top = event.rect.top - 1 + topsideposition + 1;
        event.rect.buttom = targetDims.buttom - 1 + topsideposition + 1;
        event.rect.left = event.rect.left - 1 + rightsidemagnatic + 3;
        event.rect.right = event.rect.right - 1 + rightsidemagnatic + 3;

        event.rect["data_x"] =
          event.target.dataset.x - 1 + rightsidemagnatic + 3;
        event.rect["data_y"] = event.target.dataset.y - 1 + topsideposition + 1;
        // event.rect.top = event.rect.top-1+topsideposition+1;
        event.rect["eventName"] = event.target.innerHTML;
        event.rect["eventClass"] = event.target.className;
        localStorage.setItem(id, JSON.stringify(event.rect));
      }

      //}
    }
  }
  if (j == 0) {
    // event.target.style.marginLeft='0px';
    // event.target.style.marginRight='0px';
    let id = pppp;
    event.rect["data_x"] = event.target.dataset.x;
    event.rect["data_y"] = event.target.dataset.y;
    event.rect["eventName"] = event.target.innerHTML;
    event.rect["eventClass"] = event.target.className;

    if (frontdropzoneflag) {
      frontdropzoneflag = false;
      console.log("not save in local storage");
    } else if (backdropzoneflag) {
      backdropzoneflag = false;
      console.log("not save in local storage");
    } else {
      localStorage.setItem(pppp, JSON.stringify(event.rect));
    }
    //localStorage.setItem(id, JSON.stringify(event.rect));
  }
}

const createFileName = (extension = "", ...names) => {
  if (!extension) {
    return "";
  }
  return `${names.join("")}.${extension}`;
};
interface WhiteboardProps {}
// const Whiteboard: FC<WhiteboardProps> = () => (
const Whiteboard: FC<WhiteboardProps> = () => {
  const [lgShow, setLgShow] = useState(false);
  const [valueState, setValueState] = useState("");
  const [valueChnageState, setValueChangeState] = useState("");
  const [value, setValue] = useState(false);
  const [selected, setSelected] = React.useState(false);
  const [frontModal, setFrontModal] = React.useState(false);
  const [initialWidth, setInitialWidth] = React.useState(700);
  const [isSound, setIsSound] = useState(true);
 


 
  function FrontBoard() {
    var frontboard = document.getElementById("frontwhiteboard");
    var backboard = document.getElementById("backwhiteboard");
    var changebtntext = document.getElementById("hidefrontbutton");
    var disablebtnback = document.getElementById("hidebackbutton");
    if (frontboard.style.display === "none") {
      setFrontModal(false);
      frontboard.style.display = "block";
      changebtntext.innerHTML = "Hide Front";
      document.getElementById("Maindiv").style.height = "200vh";
      disablebtnback.removeAttribute("disabled");
      backboard.style.margin = "auto";
    } else {
      setFrontModal(true);
      frontboard.style.display = "none";
      changebtntext.innerHTML = "UnHide Front";
      backboard.style.display = "block";
      backboard.style.marginTop = "-20px";
      document.getElementById("Maindiv").style.height = "100vh";
      disablebtnback.setAttribute("disabled", "true");
    }
  }
  function BackBoard() {
    var backboard = document.getElementById("backwhiteboard");
    var frontboard = document.getElementById("frontwhiteboard");
    var changebtntext = document.getElementById("hidebackbutton");
    var disablebtnfront = document.getElementById("hidefrontbutton");
    if (backboard.style.display === "none") {
      backboard.style.display = "block";
      changebtntext.innerHTML = "Hide Back";
      disablebtnfront.removeAttribute("disabled");
      document.getElementById("Maindiv").style.height = "200vh";
      // disablebtnfront.removeAttribute('disabled');
    } else {
      backboard.style.display = "none";
      changebtntext.innerHTML = "UnHide Back";
      frontboard.style.display = "block";
      // backboard.style.marginTop = "-20px";
      document.getElementById("Maindiv").style.height = "100vh";
      disablebtnfront.setAttribute("disabled", "true");
    }
  }
  // const handleChange = (event) => {
  //   const value = event.target.value
  //   console.log(value)
  //   setValueState(value)

  // };

  const closeModal = () => {
    setTopPosition(90);
    console.log("position", topPosition);
    setLgShow(false);
  };

  const ref = useRef(null);
  const { image, takeScreenshot, isLoading, clear } = useScreenshot();
  const download = (image, { name = "LearnUp", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    const current = new Date();
    const date = `${current.getDate()}-${current.getMonth() +
      1}-${current.getFullYear()} ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
    name = name + date.toString();
    a.download = createFileName(extension, name);

    a.click();
  };

  const downloadScreenshot = () => takeScreenshot(ref.current).then(download);
  const navigate = useNavigate();

  useEffect(() => {
    
   var soundWhooshWhiteB = localStorage.getItem('soundWhooshWB');
   var soundTilesWhiteB = localStorage.getItem('soundTilesWB');
   if(soundWhooshWhiteB === 'true'){
          soundWhooshWB = true
   }else{
          soundWhooshWB = false
   }
   if(soundTilesWhiteB === 'true'){
         soundTilesWB = true
   }else{
          soundTilesWB = false
   }
       
    //google doc //
    // function start() {
    //   gapi.client.init({
    //     apiKey: API_Key,
    //     clientId: clientId,
    //     scope: scope,
    //   });
    // }
    // gapi.load("client:auth2", start);

    const renderGoogleButton = localStorage.getItem("GLogin");
    console.log("GoogleLOginButton", renderGoogleButton);
    const renderNormalLogoutButton = localStorage.getItem("NLogin");
    console.log("Normal login button", renderNormalLogoutButton);

    if (renderGoogleButton == "1") {
      document.getElementById("GoogleLogout").style.display = "block";
    }

    if (renderNormalLogoutButton == "1") {
      document.getElementById("NormalLogout").style.display = "block";
    }

    //google doc end//
    // if(localStorage.getItem('Role')=="Tutor") {
    var getAllTiles = document.querySelectorAll(".drag-drop");
    var values = [],
      LocalStorageitemsKeys = Object.keys(localStorage),
      i = LocalStorageitemsKeys.length;
    // for (var l = 1; l <= LocalStorageitemsKeys.length; l++) {
    while (i--) {
      let divdata = JSON.parse(localStorage.getItem(LocalStorageitemsKeys[i]));
      // console.log(typeof divdata);
      // console.log(divdata["left"]);
      // console.log(divdata["right"]);
      // console.log(divdata["top"]);
      // console.log(divdata);
      // console.log(divdata[""]);

      for (var g = 0; g < getAllTiles.length; g++) {
        //console.log(getAllTiles[g])
        const parse_array = LocalStorageitemsKeys[i].split("_");
        // if(parse_array[0]=="hide" && getAllTiles[g].id==(parse_array[1]+"_"+parse_array[2]))
        // {
        //   document.getElementById(getAllTiles[g].id).style.visibility = 'hidden';
        // }
        if (LocalStorageitemsKeys[i] == getAllTiles[g].id) {
          var elem = document.getElementById(getAllTiles[g].id);
          elem.style.transform =
            "translate(" +
            divdata["data_x"] +
            "px, " +
            divdata["data_y"] +
            "px)";
          getAllTiles[g].setAttribute("left", divdata["left"]);
          getAllTiles[g].setAttribute("right", divdata["right"]);
          getAllTiles[g].setAttribute("top", divdata["top"]);
          getAllTiles[g].setAttribute("data-x", divdata["data_x"]);
          getAllTiles[g].setAttribute("data-y", divdata["data_y"]);
        }
        //  else if (
        //   parse_array[0] + "_" + parse_array[1] + "_" + parse_array[2] ==
        //     getAllTiles[g].id &&
        //   parse_array[3]
        // ) {
        //   var newDiv = document.createElement("div");
        //   newDiv.setAttribute("id", LocalStorageitemsKeys[i]);
        //   newDiv.style.transform = "translate(" + divdata["data_x"] + "px, " + divdata["data_y"] + "px)";
        //   newDiv.setAttribute("left", divdata["left"]);
        //   newDiv.setAttribute("right", divdata["right"]);
        //   newDiv.setAttribute("top", divdata["top"]);
        //   newDiv.setAttribute("data-x", divdata["data_x"]);
        //   newDiv.setAttribute("data-y", divdata["data_y"]);
        //   if (
        //     parse_array[1] == "frontofboard" &&
        //     parse_array[parse_array.length - 1] != "draginbackboard"
        //   ) {
        //     document.getElementById("frontwhiteboard").appendChild(newDiv);
        //     document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
        //       divdata["eventName"];
        //     document.getElementById(LocalStorageitemsKeys[i]).className =
        //       divdata["eventClass"];
        //     var elem = document.getElementById(LocalStorageitemsKeys[i]);
        //     elem.style.position = "absolute";
        //     //elem.style.margin = "0px";
        //     // elem.style.left=divdata["left"]-parseFloat('30')+'px';
        //     // elem.style.top=divdata["top"]-parseFloat('45')+'px';
        //     //.style.left = divdata["left"] + "px";
        //     //elem.style.top = divdata["top"] + "px";
        //     //elem.style.transform = "";
        //   } else if (
        //     parse_array[1] == "frontofboard" &&
        //     parse_array[parse_array.length - 1] == "draginbackboard"
        //   ) {
        //     document.getElementById("backwhiteboard").appendChild(newDiv);
        //     document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
        //       divdata["eventName"];
        //     document.getElementById(LocalStorageitemsKeys[i]).className =
        //       divdata["eventClass"];
        //     var elem = document.getElementById(LocalStorageitemsKeys[i]);
        //     elem.style.position = "absolute";
        //     // elem.style.margin = "0px";
        //     // elem.style.left = divdata["left"] + "px";
        //     // elem.style.top = divdata["top"] + "px";
        //     // elem.style.transform = "";
        //   } else if (
        //     parse_array[1] == "backofboard" &&
        //     parse_array[parse_array.length - 1] != "draginfrontboard"
        //   ) {
        //     document.getElementById("backwhiteboard").appendChild(newDiv);
        //     document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
        //       divdata["eventName"];
        //     document.getElementById(LocalStorageitemsKeys[i]).className =
        //       divdata["eventClass"];
        //     var elem = document.getElementById(LocalStorageitemsKeys[i]);
        //     elem.style.position = "absolute";
        //     // elem.style.left = divdata["left"] + "px";
        //     // elem.style.top = divdata["top"] + "px";
        //     // elem.style.transform = "";
        //     // elem.style.margin = "0px";
        //   } else if (
        //     parse_array[1] == "backofboard" &&
        //     parse_array[parse_array.length - 1] == "draginfrontboard"
        //   ) {
        //     document.getElementById("frontwhiteboard").appendChild(newDiv);
        //     document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
        //       divdata["eventName"];
        //     document.getElementById(LocalStorageitemsKeys[i]).className =
        //       divdata["eventClass"];
        //     var elem = document.getElementById(LocalStorageitemsKeys[i]);
        //     // elem.style.position = "absolute";
        //     // elem.style.left = divdata["left"] + "px";
        //     // elem.style.top = divdata["top"] + "px";
        //     // elem.style.transform = "";
        //   }

        //   //elem.style.transform = "translate(" + divdata["data_x"] + "px, " + divdata["data_y"] + "px)";
        // } 
        else if (
          parse_array[0] + "_" + parse_array[1] + "_" + parse_array[2] ==
            getAllTiles[g].id &&
          parse_array[3]
        ) {
          var newDiv = document.createElement("div");
          newDiv.setAttribute("id", LocalStorageitemsKeys[i]);
          newDiv.style.transform =
            "translate(" +
            divdata["data_x"] +
            "px, " +
            divdata["data_y"] +
            "px)";
          newDiv.setAttribute("left", divdata["left"]);
          newDiv.setAttribute("right", divdata["right"]);
          newDiv.setAttribute("bottom", divdata["bottom"]);
          newDiv.setAttribute("top", divdata["top"]);

          newDiv.setAttribute("data-x", divdata["data_x"]);
          newDiv.setAttribute("data-y", divdata["data_y"]);
          if (
            parse_array[1] == "frontofboard" &&
            parse_array[parse_array.length - 1] != "draginbackboard"
          ) {
            document.getElementById("frontwhiteboard").appendChild(newDiv);
            document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
              divdata["eventName"];
            document.getElementById(LocalStorageitemsKeys[i]).className =
              divdata["eventClass"];
            var elem = document.getElementById(LocalStorageitemsKeys[i]);
            elem.style.position = "absolute";
            // elem.style.margin="0px";
            // // elem.style.left=divdata["left"]-parseFloat('30')+'px';
            // // elem.style.top=divdata["top"]-parseFloat('45')+'px';
            // elem.style.left=divdata["left"]+'px';
            // elem.style.top=divdata["top"]+'px';
            // elem.style.transform="";
          } else if (
            parse_array[1] == "frontofboard" &&
            parse_array[parse_array.length - 1] == "draginbackboard"
          ) {
            document.getElementById("backwhiteboard").appendChild(newDiv);
            document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
              divdata["eventName"];
            document.getElementById(LocalStorageitemsKeys[i]).className =
              divdata["eventClass"];
            var elem = document.getElementById(LocalStorageitemsKeys[i]);
            elem.style.position = "absolute";
            // elem.style.margin="0px"
            // elem.style.left=divdata["left"]+'px';
            // elem.style.top=divdata["top"]+'px';
            // elem.style.transform="";
          } else if (
            parse_array[1] == "backofboard" &&
            parse_array[parse_array.length - 1] != "draginfrontboard"
          ) {
            document.getElementById("backwhiteboard").appendChild(newDiv);
            document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
              divdata["eventName"];
            document.getElementById(LocalStorageitemsKeys[i]).className =
              divdata["eventClass"];
            var elem = document.getElementById(LocalStorageitemsKeys[i]);
            elem.style.position = "absolute";
            // elem.style.left=divdata["left"]+'px';
            // elem.style.top=divdata["top"]+'px';
            // elem.style.transform="";
            // elem.style.margin='0px'
          } else if (
            parse_array[1] == "backofboard" &&
            parse_array[parse_array.length - 1] == "draginfrontboard"
          ) {
            document.getElementById("frontwhiteboard").appendChild(newDiv);
            document.getElementById(LocalStorageitemsKeys[i]).innerHTML =
              divdata["eventName"];
            document.getElementById(LocalStorageitemsKeys[i]).className =
              divdata["eventClass"];
            var elem = document.getElementById(LocalStorageitemsKeys[i]);
            elem.style.position = "absolute";
            // elem.style.left=divdata["left"]+'px';
            // elem.style.top=divdata["top"]+'px';
            // elem.style.transform="";
          }

          //elem.style.transform = "translate(" + divdata["data_x"] + "px, " + divdata["data_y"] + "px)";
        }
      }
      //  }
      console.log("Component mounted");
    }
    return () => {
      console.log("Component will be unmount");
    };
  }, []);

  const _soundWhooshWb = () => {
      if(soundWhooshWB === true){
            soundWhooshWB = false
            localStorage.setItem('soundWhooshWB', JSON.stringify(false));
      }else{
            soundWhooshWB = true
            localStorage.setItem('soundWhooshWB', JSON.stringify(true));
      }
  }

  const _soundTilesWb = () => {
    if(soundTilesWB === true){
         soundTilesWB = false
          localStorage.setItem('soundTilesWB', JSON.stringify(false))
    }else{
        soundTilesWB = true
          localStorage.setItem('soundTilesWB', JSON.stringify(true));
    }
}

  useEffect(() => {
    const accesssToken = localStorage.getItem("TockenID");
    console.log("token", accesssToken);
    if (accesssToken) {
      // localStorage.setItem('NLogin', '1');
      //  setNLogin('1')
      navigate("/home");
    } else {
      navigate("/login");
    }
  }, []);

  // function createFile() {
  //   var accessToken = gapi.auth.getToken().access_token;
  //   var fileName = "hello" + "Notes," + new Date();
  //   fetch("https://docs.googleapis.com/v1/documents", {
  //     method: "POST",
  //     headers: new Headers({ Authorization: "Bearer" + accessToken }),
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then(function(val) {
  //       console.log(val);
  //       console.log(val.documentId);
  //     });
  // }
  const [boxForMarge, setBoxForMarge] = useState([]);
  const [topPosition, setTopPosition] = useState(10);

  // let boxForMarge = ["frg", "g"];
  const newBoxForMarge = () => {
    const prevBoxs = [...boxForMarge];
    console.log("test");
    prevBoxs.push(Math.random());
    setBoxForMarge(prevBoxs);
    console.log("boxForMarge", boxForMarge);
  };
  console.log("boxForMarge", boxForMarge);
  const deleteBox = (boxNum) => {
    setBoxForMarge((prevState) => {
      return prevState.filter((e) => e !== boxNum);
    });
  };

  let printBoxForMarge = boxForMarge.map((box, index) => {
    return (
      <div
        id="outer-dropzone"
        style={{ margin: "5px" }}
        className="dropzone col-lg-2 col-md-2 col-sm-2"
        key={index}
      >
        <p
          className="close-box"
          onClick={() => {
            deleteBox(box);
          }}
        >
          &times;
        </p>
      </div>
    );
  });

  return (
    <>
      <Helmet>
        <link rel="Stylesheet" type="text/css" href="/demo/demo.css" />
        <link
          rel="Stylesheet"
          type="text/css"
          href="/lib/wColorPicker.min.css"
        />
        <link rel="Stylesheet" type="text/css" href="/wPaint.min.css" />
        <script async type="text/javascript" src="/lib/index.js"></script>
      </Helmet>
      <div className={styles.Whiteboard}>
        <div className="" id="Maindiv">
          {/* menu bar */}

          <div className="navbar row">
            <div className="col-2"></div>
            <div className="col-5">
              {/* <i className="bi bi-bootstrap-reboot"></i> */}
              <button
                id="hidefrontbutton"
                className="button btn btn-light rounded mx-2"
                onClick={FrontBoard}
              >
                {"Hide Front"}
              </button>
              {/* <button className='button btn btn-light rounded mx-2' onClick={FrontBoard}>
                  {"UnHide Front"}
                </button> */}
              <button
                id="hidebackbutton"
                className="button btn btn-light rounded mx-2"
                onClick={BackBoard}
              >
                {"Hide Back"}
              </button>
              {/* <button className='button btn btn-light rounded mx-2' onClick={BackBoard}>
                  {"UnHide Back"}
                </button> */}
              <button
                className="button btn btn-light rounded mx-2"
                onClick={resetdiv}
              >
                Reset
              </button>
            </div>
            <div className=" col-2 icon-class-navbar d-flex">
              {/* <button>Anotation</button> */}
              {/* {isSound ? (
              <i className="bi bi-volume-up icon-sound"
                  onClick={() => setIsSound(false)}
              ></i>
              
            ) : ( 
              <i className="icon-sound-off icon-sound mb-4"
                 onClick={() => setIsSound(true)}
          > <BiVolumeMute/></i>
            )} */}
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <i className="icon-sound">
                    {" "}
                    <BsFillVolumeDownFill />
                  </i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    <i onClick={_soundWhooshWb}>
                    
                        <FormControlLabel control={<Switch 
                          
                          defaultChecked={soundWhooshWB ? true : false}
                         color="warning"/>} label="Whoosh" labelPlacement="end" />
                     
                    </i>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    <i onClick={_soundTilesWb}>
                       <FormControlLabel control={<Switch
                       defaultChecked={soundTilesWB ? true : false}
                       color="warning" />} label="Tiles  " labelPlacement="end"/>
                     </i>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <i
                className="bi bi-camera-fill  icon-camera"
                onClick={() => downloadScreenshot()}
              ></i>
              <i className="bi bi-person icon-user"></i>
            </div>
          </div>

          {/* <div id="whiteboard"  style={myStyle}> */}

          <div
            id="frontwhiteboard"
            className="whiteboard dropzoneFrontboard "
            style={{ display: "block" }}
          >
            <div className="container">
              <div id="div_consonants"></div>
              {printBoxForMarge}

              <div className="_consonants">
                {WhiteBoardJson[0].Consonants._consonants.map((items) => {
                  // console.log(items.length )
                  // switch (items.length) {
                  //   case 1:
                  return (
                    <div
                      id={
                        items +
                        "_" +
                        WhiteBoardJson[0].Consonants.frontboard +
                        `${items == "e" ? "_vowels" : "_consonants"}`
                      }
                      className={`drag-drop drag-drop-one noselect ${
                        items == "e"
                          ? "drag-drop_Vowels e-drag-drop_Vowels"
                          : "drag-drop_white"
                      } ${
                        items.length < 3
                          ? "tile-less-than-three-of-length"
                          : "tile-more-than-three-of-length"
                      }`}
                      // style={{
                      //   marginLeft: items == "e" ? "20px !important" : null,
                      // }}
                      custom-attribute={
                        items +
                        "_" +
                        WhiteBoardJson[0].Consonants.frontboard +
                        "_consonants"
                      }
                    >
                      {" "}
                      {items}{" "}
                    </div>
                  );
                })}
              </div>
              {/* <br /> */}
              <div
                className="_consonantteams"
                style={{ textAlign: "right", marginRight: "11px" }}
              >
                {WhiteBoardJson[0].Consonant_Teams._consonant_teams.map(
                  (items) => {
                    return (
                      <div
                        id={
                          items +
                          "_" +
                          WhiteBoardJson[0].Consonants.frontboard +
                          "_consonantteams"
                        }
                        style={{ textAlign: "center" }}
                        className={`drag-drop drag-drop_white drag-drop-one noselect _consonant_teams ${
                          items.length < 3
                            ? "tile-less-than-three-of-length"
                            : "tile-more-than-three-of-length"
                        }`}
                      >
                        {" "}
                        {items}{" "}
                      </div>
                    );
                  }
                )}
              </div>

              <div className=" _prefixes">
                <div className="row">
                  <div className="col-1 col-wid-adj"></div>

                  <div className="col-12">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className=" ">
                          {/* <br /> */}

                          <div className="_pre" style={{ marginLeft: "-35px" }}>
                            {WhiteBoardJson[0].R_Family._r_family.map(
                              (items) => {
                                // console.log(items.length )

                                return (
                                  <>
                                    <div
                                      id={
                                        items +
                                        "_" +
                                        WhiteBoardJson[0].R_Family.frontboard +
                                        "_rfamily"
                                      }
                                      className={`drag-drop drag-drop_Vowels drag-drop-two noselect ${
                                        items.length < 3
                                          ? "tile-less-than-three-of-length"
                                          : "tile-more-than-three-of-length"
                                      }`}
                                    >
                                      {" "}
                                      {items}
                                    </div>
                                    <br />
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div
                          className="_Vowel "
                          style={{ marginLeft: "-60px" }}
                        >
                          {WhiteBoardJson[0].Vowel._vowel.map(
                            (items, index) => {
                              // console.log(items.length )
                              return (
                                <>
                                  <div
                                    id={
                                      items +
                                      "_" +
                                      WhiteBoardJson[0].Vowel.frontboard +
                                      "_vowels"
                                    }
                                    // style={{
                                    //   marginLeft: `${index * 40}px`,
                                    // }}
                                    // style={{ backgroundColor: "blue" }}
                                    className={`drag-drop drag-drop_prefix drag-drop-one noselect ${
                                      items.length < 3
                                        ? "tile-less-than-three-of-length"
                                        : "tile-more-than-three-of-length"
                                    }`}
                                  >
                                    {" "}
                                    {items}
                                  </div>
                                  <br />
                                </>
                              );
                            }
                          )}
                        </div>
                        <div style={{ marginTop: "60px" }}>
                          <div className="" style={{ marginLeft: "-60px" }}>
                            {WhiteBoardJson[0].row_2.data.map(
                              (items, index) => {
                                // console.log(items.length )
                                return (
                                  <>
                                    <div
                                      id={
                                        items +
                                        "_" +
                                        WhiteBoardJson[0].row_2.frontboard +
                                        "_diphthongs"
                                      }
                                      style={{ textAlign: "center" }}
                                      className={`drag-drop drag-drop_prefix drag-drop-one noselect text-center ${items}_frontofboard_diphthongs ${
                                        items.length < 3
                                          ? "tile-less-than-three-of-length"
                                          : "tile-more-than-three-of-length"
                                      }`}
                                    >
                                      {" "}
                                      {items}
                                    </div>
                                    {/* <br /> */}
                                  </>
                                );
                              }
                            )}
                            {WhiteBoardJson[0].row_2.yellow.map(
                              (items, index) => {
                                // console.log(items.length )
                                return (
                                  <>
                                    <div
                                      id={
                                        items +
                                        "_" +
                                        WhiteBoardJson[0].row_2.frontboard +
                                        "_diphthongs"
                                      }
                                      style={{ textAlign: "center" }}
                                      className={`drag-drop drag-drop_Yellows drag-drop-one noselect text-center ${items}_frontofboard_diphthongs ${
                                        items.length < 3
                                          ? "tile-less-than-three-of-length"
                                          : "tile-more-than-three-of-length"
                                      }`}
                                    >
                                      {" "}
                                      {items}
                                    </div>
                                    {/* <br /> */}
                                  </>
                                );
                              }
                            )}
                          </div>
                          <div
                            style={{
                              marginTop: "10px",
                              textAlign: "right",
                              marginRight: "0px", //20px
                            }}
                          >
                            {WhiteBoardJson[0].row_3.data.map(
                              (items, index) => {
                                // console.log(items.length )
                                return (
                                  <>
                                    <div
                                      id={
                                        items +
                                        "_" +
                                        WhiteBoardJson[0].row_3.frontboard +
                                        "_diphthongs"
                                      }
                                      style={{ textAlign: "center" }}
                                      className={`drag-drop drag-drop_prefix drag-drop-one noselect text-center ${items}_frontofboard_diphthongs ${
                                        items.length < 3
                                          ? "tile-less-than-three-of-length"
                                          : "tile-more-than-three-of-length"
                                      }`}
                                    >
                                      {" "}
                                      {items}
                                    </div>
                                    {/* <br /> */}
                                  </>
                                );
                              }
                            )}
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              marginTop: "-2px",
                            }}
                          >
                            {WhiteBoardJson[0].row_4.data.map(
                              (items, index) => {
                                // console.log(items.length )
                                return (
                                  <>
                                    <div
                                      id={
                                        items +
                                        "_" +
                                        WhiteBoardJson[0].row_4.frontboard +
                                        "_diphthongs"
                                      }
                                      style={{ textAlign: "center" }}
                                      className={`drag-drop drag-drop_prefix drag-drop-one noselect text-center ${items}_frontofboard_diphthongs ${
                                        items.length < 3
                                          ? "tile-less-than-three-of-length"
                                          : "tile-more-than-three-of-length"
                                      }`}
                                    >
                                      {" "}
                                      {items}
                                    </div>
                                    {/* <br /> */}
                                  </>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="_pre " style={{ textAlign: "right" }}>
                          {WhiteBoardJson[0].Short_Pieces._short_pieces.map(
                            (items) => {
                              // console.log(items.length )
                              return (
                                <>
                                  <div
                                    id={
                                      items +
                                      "_" +
                                      WhiteBoardJson[0].Short_Pieces
                                        .frontboard +
                                      "_shortpieces"
                                    }
                                    // style={{ marginLeft: `${index * 2}px` }}
                                    style={{ textAlign: "center" }}
                                    className={`drag-drop drag-drop_white drag-drop-one noselect _shortpieces ${
                                      items.length < 3
                                        ? "tile-less-than-three-of-length"
                                        : "tile-more-than-three-of-length"
                                    } `}
                                  >
                                    {" "}
                                    {items}{" "}
                                  </div>
                                  <br />
                                </>
                              );
                            }
                          )}
                        </div>
                        <div className="_pre">
                          {WhiteBoardJson[0].Bigger_Pieces._bigger_pieces.map(
                            (items) => {
                              // console.log(items.length )
                              return (
                                <>
                                  <div
                                    id={
                                      items +
                                      "_" +
                                      WhiteBoardJson[0].Bigger_Pieces
                                        .frontboard +
                                      "_biggerpieces"
                                    }
                                    // style={{ marginLeft: `${index * 2}px` }}
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                    className={`drag-drop drag-drop_white drag-drop-one noselect _bigger_pieces ${
                                      items.length < 3
                                        ? "tile-less-than-three-of-length"
                                        : "tile-more-than-three-of-length"
                                    }`}
                                  >
                                    {" "}
                                    {items}{" "}
                                  </div>
                                  <br />
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "start" }}>
                    <div className="_pre" style={{ marginLeft: "10px" }}>
                      {WhiteBoardJson[0].Prefixes._prefixes.map((items) => {
                        // console.log(items.length )
                        return (
                          <>
                            <div
                              id={
                                items +
                                "_" +
                                WhiteBoardJson[0].Prefixes.frontboard +
                                "_prefixes"
                              }
                              // style={{ marginLeft: `${index * 2}px` }}
                              style={{ textAlign: "center" }}
                              className={`drag-drop drag-drop_white drag-drop-one noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                            <br />
                          </>
                        );
                      })}
                    </div>
                    <div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div style={{ marginTop: "25px" }}>
                          {WhiteBoardJson[0].row_5.yellow.map((items) => {
                            // console.log(items.length )
                            return (
                              <>
                                <div
                                  id={
                                    items +
                                    "_" +
                                    WhiteBoardJson[0].row_5.frontboard +
                                    "_yellows"
                                  }
                                  // style={{ marginLeft: `${index * 2}px` }}
                                  style={{ textAlign: "center" }}
                                  className={`drag-drop drag-drop_Yellows drag-drop-one noselect text-center ${
                                    items.length < 3
                                      ? "tile-less-than-three-of-length"
                                      : "tile-more-than-three-of-length"
                                  }`}
                                >
                                  {" "}
                                  {items}{" "}
                                </div>
                                <br />
                              </>
                            );
                          })}
                          {WhiteBoardJson[0].row_6.yellow.map((items) => {
                            // console.log(items.length )
                            return (
                              <>
                                <div
                                  id={
                                    items +
                                    "_" +
                                    WhiteBoardJson[0].row_6.frontboard +
                                    "_yellows"
                                  }
                                  // style={{ marginLeft: `${index * 2}px` }}
                                  style={{ textAlign: "center" }}
                                  className={`drag-drop drag-drop_Yellows drag-drop-one noselect text-center ${
                                    items.length < 3
                                      ? "tile-less-than-three-of-length"
                                      : "tile-more-than-three-of-length"
                                  }`}
                                >
                                  {" "}
                                  {items}{" "}
                                </div>
                                <br />
                              </>
                            );
                          })}
                          {WhiteBoardJson[0].row_7.yellow.map((items) => {
                            // console.log(items.length )
                            return (
                              <>
                                <div
                                  id={
                                    items +
                                    "_" +
                                    WhiteBoardJson[0].row_7.frontboard +
                                    "_yellows"
                                  }
                                  // style={{ marginLeft: `${index * 2}px` }}
                                  style={{
                                    textAlign: "center",
                                    marginLeft: "80px",
                                  }}
                                  className={`drag-drop drag-drop_Yellows drag-drop-one noselect text-center ${
                                    items.length < 3
                                      ? "tile-less-than-three-of-length"
                                      : "tile-more-than-three-of-length"
                                  }`}
                                >
                                  {" "}
                                  {items}{" "}
                                </div>
                                <br />
                              </>
                            );
                          })}
                        </div>
                        <div style={{ marginLeft: "-45px" }} className="">
                          <div>
                            {WhiteBoardJson[0].row_5.data.map((items) => {
                              // console.log(items.length )
                              return (
                                <>
                                  <div
                                    id={
                                      items +
                                      "_" +
                                      WhiteBoardJson[0].row_5.frontboard +
                                      "_walkers"
                                    }
                                    // style={{ marginLeft: `${index * 2}px` }}
                                    style={{
                                      textAlign: "center",
                                    }}
                                    className={`drag-drop drag-drop_prefix drag-drop-one noselect text-center ${
                                      items.length < 3
                                        ? "tile-less-than-three-of-length"
                                        : "tile-more-than-three-of-length"
                                    }`}
                                  >
                                    {" "}
                                    {items}{" "}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                          <div
                            style={{ marginTop: "63px", marginRight: "-118px" }}
                          >
                            {WhiteBoardJson[0].Reds._reds.map((items) => {
                              // console.log(items.length )
                              return (
                                <>
                                  <div
                                    id={
                                      items +
                                      "_" +
                                      WhiteBoardJson[0].Reds.frontboard +
                                      "_reds"
                                    }
                                    // style={{ marginLeft: `${index * 2}px` }}
                                    style={{
                                      textAlign: "center",
                                    }}
                                    className={`drag-drop drag-drop_Reds drag-drop-one noselect ${
                                      items.length < 3
                                        ? "tile-less-than-three-of-length"
                                        : "tile-more-than-three-of-length"
                                    }`}
                                  >
                                    {" "}
                                    {items}{" "}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1 col-wid-adj"></div>
                  <div className="col-1 col-wid-adj"></div>
                  <div className="col-1 col-wid-adj">
                    <div className="_pre"></div>
                  </div>

                  <br />
                  <div className="_consonants" style={{marginBottom: '40px'}}>
                    {WhiteBoardJson[0].Suffixes._suffixes_left.map((items) => {
                      // console.log(items.length )

                      return (
                        <div
                          id={
                            items +
                            "_" +
                            WhiteBoardJson[0].Suffixes.frontboard +
                            "_suffixes"
                          }
                          className={`drag-drop drag-drop-one noselect drag-drop_white ${
                            items.length < 3
                              ? "tile-less-than-three-of-length"
                              : "tile-more-than-three-of-length"
                          }`}
                        >
                          {" "}
                          {items}{" "}
                        </div>
                      );
                    })}

                    {WhiteBoardJson[0].Suffixes._suffixes_right.map((items) => {
                      // console.log(items.length )

                      return (
                        <div
                          id={
                            items +
                            "_" +
                            WhiteBoardJson[0].Suffixes.frontboard +
                            "_suffixes"
                          }
                          className={`drag-drop drag-drop-one noselect drag-drop_white ${
                            items.length < 3
                              ? "tile-less-than-three-of-length"
                              : "tile-more-than-three-of-length"
                          }`}
                          // custom-attribute={
                          //   items +
                          //   "_" +
                          //   WhiteBoardJson[0].Consonants.frontboard +
                          //   "_consonants"
                          // }
                        >
                          {" "}
                          {items}{" "}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            id="backwhiteboard"
            className="backwhiteboard dropzoneBackboard"
            style={{ display: "block", marginBottom: "20px" }}
          >
            <div className="main-container">
              {/* <div className="row pt-3" > */}
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-2 col-lg-2 col-md-2 col-sm-2" id="rfamily">
                  <div className="_Prefixes">
                    {BackBoardJson[0].Prefixes.data.map((items) => {
                      // console.log(items.length )
                      switch (items.length) {
                        case 1:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Prefixes.backboard +
                                "_Prefixe"
                              }
                              className={`drag-drop ddrag-drop_Vowels drag-drop_prifixes-one noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 2:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Prefixes.backboard +
                                "_Prefixe"
                              }
                              className={`drag-drop drag-drop_Vowels drag-drop_prifixes-two noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 3:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Prefixes.backboard +
                                "_Prefixe"
                              }
                              className={`drag-drop drag-drop_Vowels drag-drop_prifixes-three noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 4:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Prefixes.backboard +
                                "_Prefixe"
                              }
                              className={` drag-drop drag-drop_Vowels drag-drop_prifixes-four noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 5:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Prefixes.backboard +
                                "_Prefixe"
                              }
                              className={`drag-drop drag-drop_Vowels drag-drop_prifixes-five noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        default:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Prefixes.backboard +
                                "_Prefixe"
                              }
                              className={` drag-drop drag-drop_Vowels drag-drop_prifixes-default noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                      }
                    })}
                  </div>
                  <div id="div_prefixess"></div>
                </div>
                <div
                  className="col-8 col-lg-8 col-md-8 col-sm-8"
                  id="consonants"
                >
                  <div className="_Roots">
                    {BackBoardJson[0].Roots.data.map((items) => {
                      switch (items.length) {
                        case 1:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Roots.backboard +
                                "_roots"
                              }
                              className={`drag-drop drag-drop_roots-one noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 2:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Roots.backboard +
                                "_roots"
                              }
                              className={`drag-drop drag-drop_roots-two noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 3:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Roots.backboard +
                                "_roots"
                              }
                              className={`drag-drop drag-drop_roots-three noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 4:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Roots.backboard +
                                "_roots"
                              }
                              className={`drag-drop drag-drop_roots-four noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 5:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Roots.backboard +
                                "_roots"
                              }
                              className={`drag-drop drag-drop_roots-five noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        default:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Roots.backboard +
                                "_roots"
                              }
                              className={`drag-drop drag-drop_roots-default noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                      }
                    })}
                  </div>
                  <div
                    id="position-adj"
                    style={{
                      margin: "auto",
                      marginTop: "464px ",
                      width: "auto",
                    }}
                  >
                    {BackBoardJson[0].Suffixes.new_data.map((items) => {
                      var new_items = items.replace(
                        /(\r\n|\n\r|\r|\n)/g,
                        `${(<br />)}`
                      );

                      return (
                        <div
                          id={
                            items +
                            "_" +
                            BackBoardJson[0].Suffixes.backboard +
                            "_endings"
                          }
                          style={{ height: "auto", padding: "1px 0" }}
                          className={`drag-drop drag-drop_Reds drag-drop_suffixes-one noselect ${
                            items.length < 3
                              ? "tile-less-than-three-of-length"
                              : "tile-more-than-three-of-length"
                          }`}
                        >
                          {new_items}{" "}
                        </div>
                      );
                    })}
                  </div>
                  <div id="div_rootss"></div>
                </div>
                <div className="col-2 col-lg-2 col-md-2 col-sm-2" id="endings">
                  <div className="_Endings">
                    {BackBoardJson[0].Suffixes.data.map((items) => {
                      switch (items.length) {
                        case 1:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Suffixes.backboard +
                                "_endings"
                              }
                              className={`drag-drop drag-drop_Reds drag-drop_suffixes-one noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 2:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Suffixes.backboard +
                                "_endings"
                              }
                              className={`drag-drop drag-drop_Reds drag-drop_suffixes-two noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 3:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Suffixes.backboard +
                                "_endings"
                              }
                              className={`drag-drop drag-drop_Reds drag-drop_suffixes-three noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 4:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Suffixes.backboard +
                                "_endings"
                              }
                              className={`drag-drop drag-drop_Reds drag-drop_suffixes-four noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        case 5:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Suffixes.backboard +
                                "_endings"
                              }
                              className={`drag-drop drag-drop_Reds drag-drop_suffixes-five noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                        default:
                          return (
                            <div
                              id={
                                items +
                                "_" +
                                BackBoardJson[0].Suffixes.backboard +
                                "_endings"
                              }
                              className={`drag-drop drag-drop_Reds drag-drop_suffixes-default noselect ${
                                items.length < 3
                                  ? "tile-less-than-three-of-length"
                                  : "tile-more-than-three-of-length"
                              }`}
                            >
                              {" "}
                              {items}{" "}
                            </div>
                          );
                      }
                    })}
                  </div>

                  <div id="div_endings"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReactModal
          initWidth={700}
          initHeight={330}
          //  top={topPosition}
          // axis={"x"}
          //  handle=".handle"
          //  defaultPosition={{x: 0, y: 0}}
          // position={null}
          onFocus={() => console.log(window.innerWidth)}
          className={"my-modal-custom-class"}
          onRequestClose={() => setLgShow(false)}
          isOpen={lgShow}
        >
          <div className="writing-txt">
            <h3>Writing Excercise</h3>
            <span className="close-modal">
              <a className="close-btn modal-btn" onClick={() => closeModal()}>
                &times;
              </a>
            </span>
          </div>

          <div className="body">
            <div className="container">
              <div className="row mt-4">
                {/* <div className="col-4">
                      <button className='button btn btn-light test-btn' type='submit' onClick={changeValue} >Typing</button>
                    </div> */}
                {/* {value == true && <Demo />} */}
                {value == false && <WritingExData />}
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    </>
  );
};

export default Whiteboard;
