import React, { FC } from 'react';
import styles from './Tiles.module.css';

interface TilesProps {}
class Tiles extends React.Component {

  handleId = (e) => {
   /*Well if the elements are nested event.target won't always work
     since it refers to the target that triggers the event in the first place.*/
    console.log(e.target.id);
    console.log(e.currentTarget.id);
  }

  render() {
    return (
      <button id="yourID" onDoubleClick={this.handleId}>Button</button>
    );
  }
}
// const Tiles: FC<TilesProps> = () => (
//   <div className={styles.Tiles}>
//     Tiles Component
//   </div>
// );

// export default Tiles;
