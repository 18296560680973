import React from 'react'
import { useState } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { useNavigate ,useLocation,useParams} from 'react-router-dom';

import logo from '../../../src/assets/images/v2_7.png'
import '../../contexts/style.css'
import { FaUserAlt, FaUnlockAlt } from "react-icons/fa";

export default function ResetPassword() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const email = query.get('email');
  const token = query.get('token');
  console.log("token, email: ", token, email);
  const [Password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  // const [input, setInput] = useState({
  //   password: '',
  //   confirmPassword: ''
  // });
 
  // const [error, setError] = useState({
  //   password: '',
  //   confirmPassword: ''
  // })
 
  // const onInputChange = e => {
  //   const { name, value } = e.target;
  //   setInput(prev => ({
  //     ...prev,
  //     [name]: value
  //   }));
  //   validateInput(e);
  // }
 
  // const validateInput = e => {
  //   let { name, value } = e.target;
  //   setError(prev => {
  //     const stateObj = { ...prev, [name]: "" };
 
  //     switch (name) {
  //       case "password":
  //         if (!value) {
  //           stateObj[name] = "Please enter Password.";
  //         } else if (input.confirmPassword && value !== input.confirmPassword) {
  //           stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
  //         } else {
  //           stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
  //         }
  //         break;
 
  //       case "confirmPassword":
  //         if (!value) {
  //           stateObj[name] = "Please enter Confirm Password.";
  //         } else if (input.password && value !== input.password) {
  //           stateObj[name] = "Password and Confirm Password does not match.";
  //         }
  //         break;
 
  //       default:
  //         break;
  //     }
 
  //     return stateObj;
  //   });
  // }




  // const handlePassword = (e) => {
  //   setPassword(e.target.value);
  //   setSubmitted(false);
  // };
  // const handleConfirmPassword = (e) => {
  //   setConfirmPassword(e.target.value);
  //   setSubmitted(false);
  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    const UserData = {
        email,
        token,
        NewPassword:Password
            };
   
    fetch('https://learnupcenterapi.azurewebsites.net/api/Subscriber/ResetPassword', {
      method: 'put',
      body: JSON.stringify(UserData),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((Response) => Response.json())
      .then((Result) => {
        console.log("in result")
        if (Result=='Done') {
          document.getElementById("ResetPasswordSuccessMsg").style.display = "block";
                        setTimeout(navigateLogin, 3000)
                        function navigateLogin() {
                            navigate("/")
                          }
        }
        else {
          console.log("Password not change");
          document.getElementById("ResetPasswordErrorMsg").style.display = "block";
          document.getElementById("ResetPasswordErrorMsg").innerHTML=Result;
          // setError(true);
          // setTimeout(() => {
          //   setError(false);
          // }, 2000);
        }
      })
  }
  function showpassword() {
    document.getElementById("showpassword").style.display = "block"
    document.getElementById("hidepassword").style.display = "none"
    document.getElementById("password-type").type = "password";
  }
  function hidepassword() {
    document.getElementById("showpassword").style.display = "none"
    document.getElementById("hidepassword").style.display = "block"
    document.getElementById("password-type").type = "text";

  }
  function showconfirmpassword() {
    document.getElementById("showconfirmpassword").style.display = "block"
    document.getElementById("hideconfirmpassword").style.display = "none"
    document.getElementById("password-confirm").type = "password";
  }
  function hideconfirmpassword() {
    document.getElementById("showconfirmpassword").style.display = "none"
    document.getElementById("hideconfirmpassword").style.display = "block"
    document.getElementById("password-confirm").type = "text";

  }
  return (
    <>
      <div className="text-center head">
        <div className='login-content' >
          <img src={logo} className="logo-img" />
          <div className="w-75 mx-auto">
            <h4 className="text-success bg-light p-1 border border-success border border-3 rounded-pill mt-1" id="ResetPasswordSuccessMsg" style={{ display: "none" }}>Password Reset Successfully.</h4>
          </div>
          <div className="w-75 mx-auto">
            <h4 className="text-danger bg-light p-1 border border-success border border-3 rounded-pill mt-1" id="ResetPasswordErrorMsg" style={{ display: "none" }}></h4>
          </div> 
          <div className='usr-txt'>Reset Password</div>
          <form >
          <div className="row">
          <div className="passfield">
              <span className='icon-adj1'> <FaUnlockAlt /></span>
          <input
                className='input-firlds-adj'
                id="password-type"
                type="password"
                name="Password"
                placeholder="Password"
                autoComplete="off"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one
               number and one uppercase and 
               lowercase letter, and at least
                8 or more characters"
                required
              />
              <i className="bi bi-eye-fill show-hide-password1" id="hidepassword" onClick={showpassword}></i>

<i className="bi bi-eye-slash-fill show-hide-password1" id="showpassword"
onClick={hidepassword}></i>
               </div>
              </div>
            {/* <div className="passfield">
              <span className='icon-adj'> <FaUnlockAlt /></span>
           
              <input className='v20_10 mt-4' placeholder='Password' id="password-type"
                type="password"
                name="password"
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}
                // value={NewPassword}
                // onChange={handlePassword}
                required ></input>
            <i className="bi bi-eye-fill show-hide-password" id="hidepassword" onClick={showpassword}></i>

          <i className="bi bi-eye-slash-fill show-hide-password" id="showpassword"
  onClick={hidepassword}
  
></i> 


            </div> 
            {error.password && <span className='text-danger'>{error.password}</span>}
            <div className="passfield">
              <span className='icon-adj'> <FaUnlockAlt /></span>
           
              <input className='v20_10 mt-4' placeholder='Confirm Password' id="password-confirm"
                type="password"
                autocomplete="off"
                name="confirmPassword"
                // value={confirmPassword}
                // onChange={handleConfirmPassword}
                value={input.confirmPassword}
                onChange={onInputChange}
                onBlur={validateInput}
                required ></input>
              
            <i className="bi bi-eye-fill show-hide-password" id="hideconfirmpassword" onClick={showconfirmpassword}></i>

            <i className="bi bi-eye-slash-fill show-hide-password" id="showconfirmpassword"
  onClick={hideconfirmpassword}
></i> 
            
            </div>
            {error.confirmPassword && <span className='text-danger'>{error.confirmPassword}</span>} */}
            <div className='mt-4'>
              <button className='login-btn' onClick={handleSubmit} type="submit">Submit</button>
            </div>         
          </form>
        </div>
        
      </div>
    </>
  )
};
