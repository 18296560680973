import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../../src/assets/images/v2_7.png';
import '../../contexts/style.css';

export default function Register() {
  const [FirstName, setFirstName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [Contact, setContact] = useState("");
  const [RoleName, setRole] = useState("Tutor");
  const Navigate = useNavigate();
  //  

  async function handlesubmit(e) {

    e.preventDefault();
    
    const UserDate = {
      FirstName,
      MiddleName,
      LastName,
      Email,
      Password,
      Contact,
      RoleName,
    };
    console.log(UserDate);
    
    //learnupuatapi.azurewebsites.net
    let Result = await fetch(
      "https://learnupcenterapi.azurewebsites.net/api/Subscriber/RegisterUser",
      {
        method: "post",
        body: JSON.stringify(UserDate),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      })
    Result = await Result.json();
    console.log("resulr", Result);


    if (Result.status == 200) {
      document.getElementById("registerSuccessMsg").style.display = "block";
      setTimeout(navigateLogin, 3000)
      function navigateLogin() {
        Navigate("/Login")
      }

      console.log("successful login");

    } else {

      console.log("email alreasy exist");
      document.getElementById("emailErrorMsg").style.display = "block";
      setTimeout(hideErrMsg, 3000)
      function hideErrMsg() {
        document.getElementById("emailErrorMsg").style.display = "none";
      }

    }
  }


  function showpassword() {
    document.getElementById("showpassword").style.display = "block"
    document.getElementById("hidepassword").style.display = "none"
    document.getElementById("password-type").type = "password";
  }
  function hidepassword() {
    document.getElementById("showpassword").style.display = "none"
    document.getElementById("hidepassword").style.display = "block"
    document.getElementById("password-type").type = "text";

  }





  return (
    <div className="text-center head">
      <div className="register-content">
        <img src={logo} className="logo-img" />
        <div className="w-75 mx-auto">
          <h4 className="text-success bg-light p-1 border border-success border border-3 rounded-pill mt-1" id="registerSuccessMsg" style={{ display: "none" }}>User Successfully Register</h4>
        </div>
        {/* <h2 className="text-white register-top">Sign up/<Link className="text-white text-decoration-none" to="/">Login</Link></h2> */}
        <form autoComplete="off" onSubmit={handlesubmit}>
          <div className='usr-txt'>Sign Up</div>
          {/* <br /> */}
          <div className="row">
            <div className="col-6">
            
              <input
                className='input-firlds-adj'
                id="FirstName"
                type="text"
                name="FirstName"
                placeholder="First Name"
                value={FirstName}
                onChange={(e) => { setFirstName(e.target.value) }}
                autoComplete='off'
                title="please enter only alphabets"
                pattern="^[A-Za-z]*$"
                required
              />
              <span class="required">*</span>
            </div>
            {/* <div className="col-6">
              <input
                className='input-firlds-adj'
                id="MiddleName"
                type="text"
                name="MiddleName"
                placeholder="Middle Name"
                value={MiddleName}
                onChange={(e) => { setMiddleName(e.target.value) }}
                autoComplete='off'
                title="please enter only alphabets"
                pattern="^[A-Za-z]*$"
                required
              />
            </div> */}
            <div className="col-6">
              <input
                className='input-firlds-adj'
                id="LastName"
                type="text"
                name="LastName"
                placeholder="Last Name"
                value={LastName}
                onChange={(e) => { setLastName(e.target.value) }}
                autoComplete='off'
                title="please enter only alphabets"
                pattern="^[A-Za-z]*$"
                required
              />
              <span class="required">*</span>
            </div>
          </div>
          <div className="row">
            
            <div className="col-6">
              <input
                className='input-firlds-adj'
                type="email"
                name="Email"
                placeholder="Email"
                autoComplete="off"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
              />
              <span class="required">*</span>
              <pre className="text-danger  my-1" id="emailErrorMsg" style={{ display: "none" }}>!Email Address Already Exists</pre>
            </div>
             <div className="col-6">
              <input
                className='input-firlds-adj'
                id="password-type"
                type="password"
                name="password_signup"
                placeholder="Password"
                autoComplete="new-password"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one
               number and one uppercase and 
               lowercase letter, and at least
                8 or more characters"
                required
              />
              <span class="required">*</span>
              {/* <i className="bi bi-eye-fill show-hide-password" id="hidepassword" onClick={showpassword}></i>

              <i className="bi bi-eye-slash-fill show-hide-password" id="showpassword"
                onClick={hidepassword} ></i> */}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <input
                className='input-firlds-adj'
                id="phoneNumber"
                type="text"
                name="Contact"
                placeholder="Phone"
                autoComplete='off'
                value={Contact}
                onChange={(e) => { setContact(e.target.value) }}
                title="please enter valid phone Number 
              it Should contain atleast 10 Number  "
                pattern="[0-9]{10}"
                maxlength="10"
                required
              />
              <span class="required">*</span>
            </div>
            <div className="col-6">
              <select
              className='role-select'
                name="RoleType"
                // id="role"
                value={RoleName}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                <option selected hidden >Role</option>
                <option value="Tutor">Tutor</option>
                <option disabled  value="Admin">Admin</option>
                <option disabled value="Student">Student</option>
                
              </select>
              
              {/* <select class="minimal"
                name="RoleType"
                value={RoleName}
                onChange={(e) => setRole(e.target.value)}
                required>
                <option selected hidden >Role</option>
                <option value="Admin">Admin</option>
                <option value="Student">Student</option>
                <option value="Tutor">Tutor</option>
              </select> */}
            </div>
          </div>
          {/* <div className="row">
            
          </div> */}
          <button className='register-btn' type="submit" >submit</button>
          
        </form>
        <Link className="my-5" style={{color:"#EF8903"}} to="/login">Back To Login Page</Link>
      </div>
    </div>
  );
}





