import React from 'react'; 
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import rough from 'roughjs/bundled/rough.esm';
import { getStroke } from 'perfect-freehand'
import './styles.css';
import { BiUndo } from 'react-icons/bi';
import { BsFillEraserFill } from 'react-icons/bs';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import {fabric} from 'fabric';
import 'fabric-history';
import resetlogo from '../../../src/assets/images/clear.png'
//  '../../../src/assets/images/v2_7.png'
const Canvas = () => {
    fabric.Canvas.prototype._historyInit = function () {
        this.historyUndo = [];
        this.historyRedo = [];
        this.extraProps = ['selectable'];
        this.historyNextState = this.historyNext();
      
        this.on({
          "object:added": this.historySaveAction,
          "object:removed": this.historySaveAction,
          "object:modified": this.historySaveAction,
          "text:editing:entered": clearText
        })
      }
      function clearText(e) {
        if (e.target.type === "i-text") {
          if (e.target.text === "Tap and Type") {
            e.target.text = "";
            e.target.hiddenTextarea.value = '';
            this.historySaveAction();
            //this.historyNextState = this.historyNext();
          }
          else{
            this.historySaveAction();
          }
        }
      }
      //  clearText(e)=function() {
      //   if (e.target.type === "i-text") {
      //     if (e.target.text === "Tap and Type") {
      //       e.target.text = "";
      //       canvas.renderAll();
      //     };
      //   }
      // }

      fabric.Canvas.prototype.historyNext = function () {
        return JSON.stringify(this.toDatalessJSON(this.extraProps));
      }
      
      fabric.Canvas.prototype.historySaveAction = function () {
        if (this.historyProcessing)
          return;
        //console.log()
        const json = this.historyNextState;
        //console.log(json);
        this.historyUndo.push(json);
        this.historyNextState = this.historyNext();
        //this.fire('history:append', { json: json });
      }
      
      fabric.Canvas.prototype.undo = function () {
        // The undo process will render the new states of the objects
        // Therefore, object:added and object:modified events will triggered again
        // To ignore those events, we are setting a flag.
        this.historyProcessing = true;
      
        const history = this.historyUndo.pop();
        if (history) {
            this.historyRedo.push(this.historyNext());
            this.historyNextState = history;
          //this.loadFromJSON(history).renderAll();
            this.loadFromJSON(history).renderAll();
            this.historyProcessing = false;
        }
      
        this.historyProcessing = false;
      }
      fabric.Canvas.prototype.redo = function () {
        // The undo process will render the new states of the objects
        // Therefore, object:added and object:modified events will triggered again
        // To ignore those events, we are setting a flag.
        this.historyProcessing = true;
      
        const history = this.historyRedo.pop();
        if (history) {
            this.historyUndo.push(this.historyNext());
            this.historyNextState = history;
            this.loadFromJSON(history).renderAll();
            this.historyProcessing = false;
        }
        else
        {
            this.historyProcessing = false;
        }
        fabric.Canvas.prototype.clearHistory = function() {
            this.historyUndo = [];
            this.historyRedo = [];
          }
        
      }
  
    console.log("Hello")

var isRedoing = false;
var h = [];

const { editor, onReady } = useFabricJSEditor();
const [canvas, setCanvas] = useState('');
const [height, setheight] = useState(230);
const [width, setwidth] = useState(700);

useEffect(() => {
    
      setCanvas(initCanvas());
      //canvas.historyInit();
    //   canvas.redo();
      window.addEventListener('resize', onWindowResize);
    }, []);  
    
    const initCanvas = () => (
      new fabric.Canvas('canvas', {
        height: height,
        width: width,
        isDrawingMode : true,
        // backgroundColor: 'pink'
      })
    );
    const onWindowResize = () => {
        //console.log("Resized")
    //   canvas.setDimensions({
    //     width: window.innerWidth * 0.7,
    //     height: window.
    //     setheight=
    //   });
    // var canvasdata=document.getElementById("canvas");
    // var ppp=document.getElementsByClassName("flexible-modal-drag-area");
    // var ppp1=document.getElementsByClassName("flexible-modal-drag-area-left");
    // var canvas2=document.getElementsByClassName("upper-canvas"); 
    // console.log(ppp);
    // canvasdata.style.width =  ppp[0].clientWidth + 'px';
    // canvasdata.style.height = ppp1[0].clientHeight-100 + 'px';
    // canvas2[0].height=ppp1[0].clientHeight-100;
    // canvas2[0].width=ppp[0].clientWidth;
    // canvas2[0].style.height=ppp1[0].clientHeight-100+'px';
    // canvas2[0].style.width=ppp[0].clientWidth+'px';
    //   setwidth(ppp[0].clientWidth);
    //   setheight(ppp1[0].clientHeight-100);
      //console.log(canvas.height());
    }
    
    const  onAddText= () => {
        canvas.isDrawingMode = false;
      var text = new fabric.IText('Tap and Type', {
        fontFamily: `"Lexend", sans-serif`,
        fontWeight: '400',
        fontSize: '20',
        left: Math.random().toFixed(2)*100,
        top: Math.random().toFixed(2)*100,
        myid: (new Date()).getTime().toString().substr(5),
        objecttype: 'text',
        //evented: true,
        selectable: true,
        editable: true
    });
    
    canvas.add(text);
    // text.selectionStart = 0;
    // text.selectionEnd = 4;
    // text.setSelectionStyles({
    // });
    // var selectionStart = 0;
    // var selectionEnd = 11;
    // var lineIndex = 0;
    // for (var i = selectionStart; i < selectionEnd; i++) {
    //   text.insertCharStyleObject(lineIndex, i, {
    //    // backgroundColor: '#0FF'
    //   })
    // }
    canvas.renderAll();
    }
    const onPencil = () => {
        canvas.isDrawingMode = true;
        canvas.selectable = false;
        fabric.Object.prototype.selectable = false;
    }
    const onEraser = () => {
        canvas.isDrawingMode = true;
        
      }
      
const onundo= ()=> {
    // canvas.undo();
    // if(canvas._objects.length>0){
    //     h.push(canvas._objects.pop());
    //     canvas.renderAll();
    //    }
    canvas.undo();
}
// canvas.on('object:modified', function () {
//     console.log('Event object:modified Triggered');
//   });
const onredo=() => {
    // canvas.redo();
    // if(h.length>0){
    //     isRedoing = true;
    //    canvas.add(h.pop());
    //   }
    canvas.redo()
}
const resetCanvas=()=>{
  //canvas.clearRect(0, 0, canvas.width, canvas.height);
  canvas.clear();
//   isRedoing = false;
//   h = [];
canvas.clearHistory();
}
  
 return (
    <div>
      <button onClick={onPencil}><i className="bi bi-pencil-fill"></i></button>
      <button onClick={onAddText}><i className="bi bi-fonts"></i></button>
      <button onClick={onundo}><i className="bi bi-arrow-counterclockwise"></i></button>
      <button onClick={onredo}><i className="bi bi-arrow-clockwise"></i></button>
      <button onClick={resetCanvas} style={{height: '32px' ,width: '32px'}}> &#67;</button>
      {/* <button onClick={onEraser}><BsFillEraserFill /></button> */}


      {/* <FabricJSCanvas className="sample-canvas" onReady={onReady} /> */}
      <canvas
      position="fixed"
                id="canvas"
                // ref={canvasRef}
                
                width={width} 
                height={height}
                // style="cursor: default;"
                // onMouseDown={handleMouseDown}
                // onMouseMove={handleMouseMove}
                // onMouseUp={handleMouseUp}
            >
                </canvas>
    </div>
    );
  }


export default Canvas;