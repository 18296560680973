import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import logo from '../../../src/assets/images/v2_7.png'
import '../../App.css'

export default function ForgetPasswordPage() {
    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');

    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
     const navigate = useNavigate();
     const handleEmail = (e) => {
        setEmail(e.target.value);
        setSubmitted(false);
        };
        const handleSubmit = (e) => {
            e.preventDefault();
            if (email === '') {
            setError(true);
            } else {
                console.log(email)
                // console.log(password)
                 fetch('https://learnupcenterapi.azurewebsites.net/api/Subscriber/ForgetPasswordGenrateToken?Email='+email, {
                //  fetch('https://localhost:44359/api/Subscriber/ForgetPasswordGenrateToken?Email='+email, {
                  method: 'get',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  }
                }).then((Response) => Response.json())
                  .then((Result) => {
                     
                    if (Result=='Password had been sent to you email address.')
                    {
                        // navigate("/sentmail")
                        document.getElementById("ResetPasswordSuccessMsg").style.display = "block";
                        setTimeout(navigateLogin, 3000)
                        function navigateLogin() {
                            navigate("/")
                          }
                        // navigate("/")
                        setSubmitted(true);
                        setError(false); 
                    }         
                    else
                    {
                        document.getElementById("ResetPasswordErrorMsg").style.display = "block";
                        document.getElementById("ResetPasswordErrorMsg").innerHTML=Result;
                        // setTimeout(navigateLogin, 3000)
                        // function navigateLogin() {
                        //     navigate("/")
                        //   }
                        // navigate("/forget-password")
                        setSubmitted(false);
                        setError(true); 
                    }
                    
                  })
            }
        };
    return (
        <div className="text-center head">
            <div className="forget-content">
            <img src={logo} className="logo-img" />
            <div className="w-75 mx-auto">
          <h4 className="text-success bg-light p-1 border border-success border border-3 rounded-pill mt-1" id="ResetPasswordSuccessMsg" style={{ display: "none" }}>Link is sent to your registered Mail Id.</h4>
        </div>
        <div className="w-75 mx-auto">
          <h4 className="text-danger bg-light p-1 border border-success border border-3 rounded-pill mt-1" id="ResetPasswordErrorMsg" style={{ display: "none" }}></h4>
        </div>  
        {/* </div>
        
            <div className="forget-content-heading text-white">
            <h2>Reset password</h2>
        </div> */}
        <form action="/login">
            <div className='usr-txt'>Reset Password</div>
                <br />
          <div className="row">
            <div className="col-6">
              <input
                className='input-firlds-adj'
                type="email"
                name="Email"
                placeholder="Email"
                autoComplete='off'
                value={email}
                onChange={handleEmail}
                // onChange={(e) => setEmail(e.target.value)}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
              />
              <pre className="text-danger  my-1" id="emailErrorMsg" style={{ display: "none" }}>!Email doesn't exist</pre>
            </div>
           </div>
                {/* <p>
                    <label id="reset_pass_lbl">Email</label><br/>
                    <input type="email" onChange={handleEmail} value={email} name="name" required />
                </p> */}
                {/* <p>
                    <label >Password</label><br/>
                    <input type="password" onChange={handlePassword} value={password} name="password" required />
                </p> */}
                <button className='register-btn' id="sub_btn"  onClick={handleSubmit}type="submit">Submit</button>
                <footer>
                {/* <p className='pt-5'>First time? <Link to="/register">Create an account</Link>.</p> */}
                <p ><Link to="/">Back to LogIn</Link></p>
            </footer>
            </form>
          
            </div>
        </div>
    )
}
