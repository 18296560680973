import React from "react"
import {Link } from 'react-router-dom';
import Learnup from '../../assets/images/v2_7.png'
export default function Thankyou(){
    return(

        // <div className="thank-you">
        //     <h1 className="">Thank you!</h1>
        //     <p className="my-2"> Thank you very much for the session</p>
        //     <Link className="text-white my-5" to="/">Back to login page</Link>
           
        // </div>
        <div className="thank-you">
           <img src={Learnup} alt="" className="ThankyouPage-logo" />
            <p className="my-2"> Thank you</p>

           
            <Link className="my-5" style={{color:"#EF8903"}} to="/login">Back To Login Page</Link>
            <div className="line-1"></div>
            <div className="line-2"></div>
            <div className="line-3"></div>
           
        </div>
    )
}