import React from "react";

import ReactDOM from "react-dom";
import "./index.css";
import "./contexts/tiles.css";
import "./contexts/tiles-galaxy.css";
import "./contexts/tiles-start.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import {Helmet} from "react-helmet";
// ReactDOM.render(
//   <React.StrictMode>

//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')

// );
ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
