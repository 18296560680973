
import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth';


// firebase

const firebaseConfig = { 
    apiKey : "AIzaSyCJPKB1LkUgi6f0GyxXnpHGuYli-4Noj70" , 
    authDomain : "learnup-a0b4e.firebaseapp.com" , 
    projectId : "learnup-a0b4e" , 
    storageBucket : "learnup-a0b4e.appspot.com" , 
    messagingSenderId : "628883393989" , 
    appId : "1:628883393989:web:3bce42edc2ad5b22714fac" 
  };

  const app = initializeApp ( firebaseConfig );


 export const authentication = getAuth(app);