exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BackSideOfTheBoard_setdiveheight__2K5_C {\r\n  height: \"70%\";\r\n  aspect-ratio: 1;\r\n  margin-left:10;\r\n  margin-top:10;\r\n  margin-bottom:10;\r\n}\r\n\r\n", ""]);

// exports
exports.locals = {
	"setdiveheight": "BackSideOfTheBoard_setdiveheight__2K5_C"
};